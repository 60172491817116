// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Home',
    path: '/',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Campus',
    path: '/campus',
    icon: icon('ic_analytics'),
  },
  {
    title: 'About us',
    path: '/about-us',
    icon: icon('ic_analytics'),
  },
  // {
  //   title: 'data flow',
  //   path: '/dataflow',
  //   icon: icon('ic_cart'),
  // },
  {
    title: 'Services',
    path: '/services',
    icon: icon('ic_blog'),
    children: [
      {
        subheader: 'Services',
        items: [
          { title: 'Health Professional lisencing and Registration for GCC', path: '/service/service-1' },
          { title: 'Online and Offline Coaching for Competitive Exams', path: '/service/service-2' },
          { title: 'Membership Registration and Good Standing', path: '/service/service-3'},
          { title: 'Ticketing and Attestation', path: '/service/service-4'},
          { title: 'BLS and ACLS Training Programs', path: '/service/service-5'},
        ]
      },
    ],
  },
  {
    title: 'Our team',
    path: '/team',
    icon: icon('ic_blog'),
  },
  {
    title: 'Contact us',
    path: '/contact-us',
    icon: icon('ic_user'),
  }
];

export default navConfig;
