import { createSlice } from '@reduxjs/toolkit';
// utils
import {Api} from '../../utils/api';

import {HOME_URLS} from '../../utils/api/urls';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  banners:[],
  homedata: [],
  aboutdata:[],
  dataflow: [],
  blogs:    [],
  blog:     {},
  courses:  [],
  teams:    [],
  testimonials:  [],
};

const slice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Home Data
    getHomeSuccess(state, action) {
      state.isLoading = false;
      state.homedata = action.payload;
    },
    // GET Banners Data
    getBannerSuccess(state, action) {
      state.isLoading = false;
      state.banners = action.payload;
    },
    // GET About Data
    getAboutSuccess(state, action) {
      state.isLoading = false;
      state.aboutdata = action.payload;
    },
    // GET Dataflow
    getDataflowSuccess(state, action) {
        state.isLoading = false;
        state.dataflow = action.payload;
      },
    // GET Blogs
    getBlogsSuccess(state, action) {
        state.isLoading = false;
        state.blogs = action.payload;
      },
    // GET Blog
    getBlogSuccess(state, action) {
        state.isLoading = false;
        state.blog = action.payload;
      },
    // GET Courses
    getCoursesSuccess(state, action) {
        state.isLoading = false;
        state.courses = action.payload;
      },      
    // GET Teams
    getTeamSuccess(state, action) {
        state.isLoading = false;
        state.teams = action.payload;
      },
    // GET Testimonials
    getTestimonialsSuccess(state, action) {
        state.isLoading = false;
        state.testimonials = action.payload;
      },    

  }
});

// Reducer
export default slice.reducer;


// ----------------------------------------------------------------------

export function getHome() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Api.getnon(`/api/basic/home`)
      dispatch(slice.actions.getHomeSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBanners() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Api.unget(HOME_URLS.banners)
      dispatch(slice.actions.getBannerSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function getAbout() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Api.getnon(`/api/basic/about`)
      dispatch(slice.actions.getAboutSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ---------------------------------------------------------------------------

export function getDataflow() {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await Api.getnon(`/api/basic/content/df`)
        dispatch(slice.actions.getDataflowSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  // ----------------------------------------------------------------------------
  export function getBlogs() {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await Api.getnon(`/api/basic/blogs`)
        console.log(response);
        dispatch(slice.actions.getBlogsSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  // -----------------------------------------------------------------------------
  export function getBlog({slug}) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await Api.getnon(`/api/basic/blog/${slug}`)
        console.log(response);
        dispatch(slice.actions.getBlogSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  //------------------------------------------------------------------------------
  export function getCourses() {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await Api.getnon(`/api/basic/courses`)
        dispatch(slice.actions.getCoursesSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  //--------------------------------------------------------------------------------
  export function getTeam() {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await Api.getnon(`/api/basic/content/teams`)
        console.log(response);
        dispatch(slice.actions.getTeamSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  //----------------------------------------------------------------------------------
  export function getTestimonials() {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await Api.getnon(`/api/basic/content/testimonials`)
        console.log(response);
        dispatch(slice.actions.getTestimonialsSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  
  //----------------------------------------------------------------------------
