import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Link, Card, Grid, Avatar, Typography, CardContent } from '@mui/material';

import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify';
// utils
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import { getAllCourses } from '../../../redux/slices/course';

// mock
// import COURSES from '../../../_mock/course';
//
// ----------------------------------------------------------------------
const StyledCardMedia = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)',
});

const StyledTitle = styled(Link)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  '&:hover': { color: '#0288D1' },
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2),
}));
const StyledAvatarName = styled(Typography)(({ theme }) => ({
  zIndex: 9,
  width:220,
  height: 20,
  position: 'absolute',
  left: theme.spacing(9),
  bottom: theme.spacing(-3),
}));
const StyledInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(3),
  color: theme.palette.text.disabled,
}));

const StyledCover = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'fill',
  position: 'absolute',
});
//
const SHARE_OPTIONS=[
  {icon: 'ic:baseline-facebook',url:'#'},
  {icon: 'mdi:instagram',url:'#'},
  {icon: 'mdi:twitter',url:'#'},
]
// ----------------------------------------------------------------------
CourseCard.propTypes = {
  course: PropTypes.object.isRequired,
};

function CourseCard({ course }) {
  const {slug, image, name, tag, addedAt } = course;
 
  const linkto=`/course/${slug}`;

  return (
    <Grid item xs={12} sm={ 6} md={ 3}>
      <Card sx={{ position: 'relative' }}>
        <StyledCardMedia
         
        >
          <SvgColor
            color="paper"
            src="/assets/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute',
              color: 'background.paper',
          
            }}
          />
          <StyledAvatar
            alt={tag.name}
            src={tag.image}
            
          />
          <StyledAvatarName
           variant="caption"
          

          >{tag.name}</StyledAvatarName>

          <StyledCover alt={name} src={image} />
        </StyledCardMedia>

        <CardContent
          sx={{
            pt: 4,
           
          }}
        >
        
          <StyledTitle
            color="inherit"
            variant="subtitle2"
            underline="hover"
            component={RouterLink}
            to={linkto}
            
          >
            {name}
          </StyledTitle>

          <StyledInfo>
            {SHARE_OPTIONS.map((share, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ml: index === 0 ? 0 : 1.5,
                
                }}
              >
                <Iconify icon={share.icon} sx={{ width: 16, height: 16, mr: 0.5 }} />
                
              </Box>
            ))}
          </StyledInfo>
        </CardContent>
      </Card>
    </Grid>
  );
}

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
MoreCourseWidget.propTypes = {
  currentcourse: PropTypes.string,
};

export default function MoreCourseWidget({currentcourse}) {
  const dispatch = useDispatch();
  const { courses, error, isLoading } = useSelector((state) => state.course);

  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  
  

  if(!courses && isLoading){
    return (
      <Typography variant="subtitle1" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
        Loading...
      </Typography>
    );
  }
  const morecourses = courses.filter((_course) => _course.slug !== currentcourse) ;

  return (
    <Grid container spacing={3}>
      {morecourses.map((course, index) => (
        <CourseCard  key={index} course={course}  />
      ))}
    </Grid>
  );
}
