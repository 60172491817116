import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Container } from '@mui/material';
// components

// APP Name
import { AppName } from '../../config';

// ----------------------------------------------------------------------

import { ConatctHero, ConatctMap, ConsultForm } from '../../sections/web/contact';

// ----------------------------------------------------------------------
export default function ContactPage() {
  return (
    <>
      <Helmet>
        <title> Contact Us | {AppName} </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ConatctHero title="Conatct Us" subheader="Features we provide" />
          </Grid>

          <Grid item xs={12}>
            <ConatctMap />
          </Grid>
          <Grid item xs={12}>
            <ConsultForm title='Contact Us' />
          </Grid>

        </Grid>
      </Container>
    </>
  );
}
