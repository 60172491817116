import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Card, Grid, Typography } from '@mui/material';

// redux
import { useDispatch, useSelector } from '../../../redux/store';
import { getStudentwalls } from '../../../redux/slices/course';
//
// ----------------------------------------------------------------------

const ContentItemStyle = styled('div')(({ theme }) => ({
  bottom: 0,
  zIndex: 9,
  width: '100%',
  display: 'block',
  position: 'absolute',
  alignItems: 'center',
  padding: theme.spacing(3),
  borderBottomLeftRadius: 16,
  backdropFilter: 'blur(8px)',
  WebkitBackdropFilter: 'blur(8px)', // Fix on Mobile
  borderBottomRightRadius: 16,
  justifyContent: 'space-between',
  backgroundColor: alpha(theme.palette.grey[900], 0.72),
  flexDirection: theme.direction === 'rtl' ? 'row-reverse' : 'row',
}));

// ----------------------------------------------------------------------
StudentCard.propTypes = {
  student: PropTypes.object,
  index: PropTypes.number,
};
// ----------------------------------------------------------------------
function StudentCard({ student, index }) {
  const {  course, user } = student;
  const { name,  avatar } = user;
  const latestItemLarge = index === 0;
  const [onHover, setOnHover] = useState(0);

  const handleMouseHover = () => {
    if(!onHover){
      setOnHover(true);
    }
  };
  const handleMouseLeave = () => {
    if(onHover){
      setOnHover(false);
    }
  };

  return (
    <Grid item xs={12} sm={6} md={ 4} sx={{p:2}}>
      <Card sx={{ position: 'relative' }}>
        <Box sx={{ position: 'relative', zIndex: 0 }} onMouseOver={handleMouseHover} onMouseLeave={handleMouseLeave}>
          <Box component="img" alt={name} src={avatar} sx={{ width: '100%', objectFit: 'scale-down' }}  />

          <ContentItemStyle sx={{display:onHover?'block':'none'}} >
            <Typography variant="subtitle1" sx={{ color: 'common.white', textTransform: 'capitalize' }}>
              {name}
            </Typography>
            <Typography variant="caption" sx={{ color: 'common.white', textTransform: 'uppercase' }}>
              {course}
            </Typography>
          </ContentItemStyle>
        </Box>

      </Card>
    </Grid>
  );
}



// ----------------------------------------------------------------------
PassoutWidget.propTypes = {
  course: PropTypes.string
};
export default function PassoutWidget({course}) {
  const dispatch = useDispatch();
  const { studentwalls, error, isLoading } = useSelector((state) => state.course);

  useEffect(() => {
    dispatch(getStudentwalls(course));
  }, [dispatch, course]);

  
  

  if(!studentwalls && isLoading){
    return (
      <Typography variant="subtitle1" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
        Loading...
      </Typography>
    );
  }
  return (
    <Grid item xs={12}>
    <Typography variant="subtitle1" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
      Some of our recent Archivers
    </Typography>
    <Typography variant="h3" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
      Archivers
    </Typography>
    <Grid container spacing={3} sx={{py:5}}>
      {studentwalls.map((student, index) => (
        <StudentCard key={index} student={student} index={index} />
      ))}
    </Grid>
    </Grid>
  );
}
