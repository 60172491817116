import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Button, Box, Link, Card, Stack, Typography } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// utils
//
import Iconify from '../../../components/iconify';
import { MIconButton } from '../../../components/@mui-extend';
import { ButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

const StyledCover = styled('img')({
  top: 0,
  maxWidth: '400px',
  objectFit: 'scale-down',
  position: 'relative',
});

// ----------------------------------------------------------------------

export default function ContactWidget() {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'md');
  const image = 'assets/illustrations/illustration_contact.png';

  const renderImage = isDesktop && (
    <StyledCover
    src={image}
    alt={'contact us'}
    sx={{
      display: 'flex',
      margin: theme.spacing(3),
    }}
  />
  );
  /*
  
  CONTACT US
Need our Help?
If you have any queries regarding our services  and it's courses, or need technical assistance, please fill in the form. Phone 1:
+91 7907880870/ 00971526353211
Email: info@asapuae.com Email: asapuae123@gmail.com
  */

  return (
    <Card sx={{ p: theme.spacing(3) }}>
      <Stack
        direction={isDesktop ? 'row' : 'column'}
        alignItems="flex-start"
        justifyContent="space-between"
        spacing={{
          xs: 0.5,
          sm: 1,
        }}
        maxHeight={'300px'}
      >
        <Box>
        {renderImage}
        </Box>

        <Stack
          direction="column"
          alignItems="center"
          justifyContent={{ xs: 'center' }}
          sx={{ margin:{xs:theme.spacing(2),md:theme.spacing(2)},py:{xs:theme.spacing(2), md:theme.spacing(4)}, textAlign: 'center' }}
        >
          <Typography variant="h3" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
          CONTACT US
          </Typography>
          <Typography variant="h6" flexWrap sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
          Need our Help?
          </Typography>
          <Typography variant="caption" flexWrap sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
          If you have any queries regarding our services  and it's courses, or need technical assistance, please fill in the form.
          </Typography>
          <Typography variant="body" flexWrap sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
            Phone +91 7907880870 / 00971526353211
          </Typography>
          <Typography variant="body" flexWrap sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
          Email: info@asapuae.com / asapuae123@gmail.com
          </Typography>
        </Stack>

        <Box sx={{ padding:{xs:theme.spacing(12)} }}>
        <a href="tel:+971526353211" title="Phone">
          <ButtonAnimate mediumClick>
             
            <Button size="large" variant="contained" sx={{underline:'none'}}>
              Call Us
            </Button>
            
          </ButtonAnimate>
          </a>
        </Box>
      </Stack>
    </Card>
  );
}
