import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Paper, Box, Link, Card, Grid, Stack, Divider, Typography } from '@mui/material';
// components
import { Widget404 } from '../../components/widegts';
import Label from '../../components/label';
import Markdown from '../../components/markdown/Markdown';

// utils
//
import Iconify from '../../components/iconify';

import CollegeListWidget from './CollegeListWidget';
import HandleApplication from './HandleApplication';
import HandleBrochure from './HandleBrochure';

// ----------------------------------------------------------------------
const StyledCardImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

FeatureWidget.propTypes = {
  course: PropTypes.object,
};

function FeatureWidget({ course }) {
  const { graduation, medium, modes, stream, duration } = course;
  const infos = [
    { title: 'Graduation', icon: 'fluent:hat-graduation-16-regular', value: graduation.title },
    { title: 'Mode', icon: 'mdi:clock', value: modes },
    { title: 'Duration', icon: 'mdi:timer-sand', value: duration },
    { title: 'Medium', icon: 'mdi:language', value: medium.title },
    { title: 'Stream', icon: 'material-symbols:stream', value: stream.title },
  ];

  return (
    <>
      <Typography variant="subtitle2" sx={{ pl: 3 }} noWrap>
        Quick info
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns:  { xs: 'repeat(2, 1fr)', md: 'repeat(5, 1fr)' },
        }}
      >
        {infos.map((site, index) => (
          <Paper key={index} variant="outlined" sx={{ py: 2.5, textAlign: 'center' }}>
            <Box sx={{ mb: 0.5 }}>
              <Iconify icon={site.icon} color={site.title} width={32} />
            </Box>
            <Stack>
              <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
                {site.title}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {site.value}
              </Typography>
            </Stack>
          </Paper>
        ))}
      </Box>
    </>
  );
}

// ----------------------------------------------------------------------

CourseMainWidget.propTypes = {
  course: PropTypes.object,
};

export default function CourseMainWidget({ course }) {
  const theme = useTheme();

  if (!course) {
    return <Widget404 item="Course" subitem={`No data found`} />;
  }
  const { slug, title, image, intro, body, stream, colleges } = course;
  const hasBrochure = false;
  return (
    <>
      <Grid item xs={12} sx={{ px: 3 }}>
        <Card>
          {/* <Box sx={{ pt: '100%', position: 'relative' }}>
            <Label
              variant="filled"
              color={'info'}
              sx={{
                zIndex: 9,
                top: 16,
                right: 16,
                position: 'absolute',
                textTransform: 'uppercase',
              }}
            >
              Rating
            </Label>
            <StyledCardImg alt={title} src={image} /> 
          </Box> */}
          <Stack spacing={2} sx={{ p: 3 }}>
            <Link color="inherit" underline="hover">
              <Typography variant="h4" noWrap>
                {title}
              </Typography>
            </Link>
            <Typography component="span" variant="boby2" sx={{ mt: 2 }}>
              <Iconify icon={'material-symbols:stream'} color={'#000'} width={24} sx={{ mx: 0.9, pt: 0.9 }} />
              {stream.title}
            </Typography>
            <Typography variant="body1">{intro}</Typography>
            <FeatureWidget course={course} />
            <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
              lg: 'repeat(4, 1fr)',
            },
            p: 2,
            cursor: 'pointer',
          }}
        >
          <HandleApplication title={title} courseslug={slug} />
          { hasBrochure && <HandleBrochure url={''} title={`${title}-brochure`} />}
        </Box>
            <Divider />

            <Markdown children={body} />
          </Stack>
        </Card>
      </Grid>
      <Typography variant="h5" sx={{ pl: 3, pt: 3 }} noWrap>
        Colleges offering {title}
      </Typography>
      <CollegeListWidget colleges={colleges} />
    </>
  );
}
