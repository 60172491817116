import { createSlice } from '@reduxjs/toolkit';
// utils
import {Api} from '../../utils/api';
import {COURSE_URLS, TESTIMONIAL_URLS, STUDENT_WALL_URLS, SERVICE_URLS, TEAM_URLS} from '../../utils/api/urls';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  hasMore: false,
  courses: [],
  testimonials: [],
  studentwalls: [],
  services: [],
  teams:[],
  course: null,
  service:null,
  index: 0,
  step: 11,
  tags: [],
};

const slice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // HAS MORE
    noHasMore(state) {
      state.hasMore = false;
    },

    getMoreCourses(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },


    // GET COURSES
    getCourseListSuccess(state, action) {
      state.isLoading = false;
      state.courses = action.payload;
    },

    // GET TESTIMONIALS
    getTestimonialListSuccess(state, action) {
      state.isLoading = false;
      state.testimonials = action.payload;
    },
      // GET STUDENT WALLS
      getStudentWallListSuccess(state, action) {
        state.isLoading = false;
        state.studentwalls = action.payload;
      },
        // GET STUDENT WALLS
        getTeamListSuccess(state, action) {
          state.isLoading = false;
          state.teams = action.payload;
        },
    // 
      // GET SERVICES
      getServiceListSuccess(state, action) {
        state.isLoading = false;
        state.services = action.payload;
      },
          // GET SERVICE
     getServiceSuccess(state, action) {
      state.isLoading = false;
      state.service = action.payload;
    },
    // GET COURSE INFINITE
      getCoursesInitial(state, action) {
        state.isLoading = false;
        state.courses = action.payload;
      },
     // GET COURSE
     getCourseSuccess(state, action) {
      state.isLoading = false;
      state.course = action.payload;
    },

     // GET TAG 
     getTagsSuccess(state, action) {
      state.isLoading = false;
      state.tags = action.payload;
    }


  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMoreCourses } = slice.actions;

// ----------------------------------------------------------------------

export function getAllCourses() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Api.unget(COURSE_URLS.list);
      dispatch(slice.actions.getCourseListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTestimonials() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Api.unget(TESTIMONIAL_URLS.list);
      dispatch(slice.actions.getTestimonialListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getStudentwalls(course) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let couseparam=''
      if (course)
      {
         couseparam=`?course=${course}`;
      }
      const response = await Api.unget(`${STUDENT_WALL_URLS.list}${couseparam}`);
      dispatch(slice.actions.getStudentWallListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTeams() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Api.unget(TEAM_URLS.list);
      dispatch(slice.actions.getTeamListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getServices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Api.unget(SERVICE_URLS.list);
      dispatch(slice.actions.getServiceListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getCoursesInitial(index, step) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Api.unget(COURSE_URLS.list, {
        params: { index, step }
      });
      const results = response.data.results.length;
      const { maxLength } = response.data;

      dispatch(slice.actions.getCoursesInitial(response.data.results));

      if (results >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//----------------------------------------------------------------------------

  export function getCourse(courseslug) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await Api.unget(`${COURSE_URLS.get}${courseslug}/`);
        dispatch(slice.actions.getCourseSuccess(response.data));
      } catch (error) {
        console.error(error);
        dispatch(slice.actions.hasError(error));
      }
    };
  }
//----------------------------------------------------------------------------

export function getService(serviceslug) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Api.unget(`${SERVICE_URLS.get}${serviceslug}/`);
      dispatch(slice.actions.getServiceSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
  // 
//----------------------------------------------------------------------------

