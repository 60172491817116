import { createSlice } from '@reduxjs/toolkit';
// utils
import { Api } from '../../utils/api';
import { CAMPUS_URLS } from '../../utils/api/urls';
import {searchres, college, course, streams} from '../../_mock/campus';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  hasMore: false,
  searchresult: null,
  course: null,
  college: null,
  streams: [],
  regions:[],
  index: 0,
  step: 11,
};

const slice = createSlice({
  name: 'campus',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // HAS MORE
    noHasMore(state) {
      state.hasMore = false;
    },
    // GET SEARCH RESULTS
    getSearchResultSuccess(state, action) {
      state.isLoading = false;
      state.searchresult = action.payload;
    },
    // GET COLLEGE
    getCollegeSuccess(state, action) {
      state.isLoading = false;
      state.college = action.payload;
    },
    // GET COURSE
    getCourseSuccess(state, action) {
      state.isLoading = false;
      state.course = action.payload;
    },
    // GET STREAMS
    getStreamSuccess(state, action) {
      state.isLoading = false;
      state.streams = action.payload;
    },
    // GET REGIONS
    getRegionsSuccess(state, action) {
      state.isLoading = false;
      state.regions = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getMoreCourses } = slice.actions;

//----------------------------------------------------------------------------

export function getCampusSearch(searchText, area, stream, page) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Api.unget(`${CAMPUS_URLS.search}${searchText}&st=${stream}&area=${area}`);
      dispatch(slice.actions.getSearchResultSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

//----------------------------------------------------------------------------

export function getStreams() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Api.unget(`${CAMPUS_URLS.streams}/`);
      dispatch(slice.actions.getStreamSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

//----------------------------------------------------------------------------

export function getRegions() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Api.unget(`${CAMPUS_URLS.regions}/`);
      dispatch(slice.actions.getRegionsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

//----------------------------------------------------------------------------

export function getCollege(collegeslug) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Api.unget(`${CAMPUS_URLS.college}${collegeslug}/`);
      dispatch(slice.actions.getCollegeSuccess(response.data));
      // dispatch(slice.actions.getCollegeSuccess(college));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

//----------------------------------------------------------------------------

export function getCourse(courseslug) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Api.unget(`${CAMPUS_URLS.course}${courseslug}/`);
      dispatch(slice.actions.getCourseSuccess(response.data));
      // dispatch(slice.actions.getCourseSuccess(course));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
//----------------------------------------------------------------------------


// 6- 30 
// 2 - 45
// 5 - 60
// 2 - QA
// dev seat $250
