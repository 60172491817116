/* eslint-disable no-unused-vars */
import axios from './axios';
import {tokenBeaer} from '../../config';

export const Api = {
    get,
    unget,
    post,
    unpost,
    dfpost,
    put,
    unput,
    dfput,
    delete: _delete
    
};


function getToken() {
    return localStorage.getItem('accessToken')
}


async function get(url,params) {
    const token= getToken()
    const requestOptions = {
        method: 'GET',
        headers: {
                'Authorization': token ? `${tokenBeaer} ${token}` : null,
    
        }, 
    };
    const response = await axios.get(url, requestOptions);
    return response;
}


async function unget(url,params) {
    const requestOptions = {
        method: 'GET',
       
    };
    const response = await axios.get(url, requestOptions);
    return response;
}


async function dfpost(url, body) {
    const token= getToken()
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
                accept: 'application/json',
                'Authorization': token ? `${tokenBeaer} ${token}` : null,
        },
        
    };
    
    const response = await axios.post(url,body, requestOptions);
    return response;
}


async function post(url, data) {
    const token= getToken()
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data',
                accept: 'application/json',
                'Authorization': token ? `${tokenBeaer} ${token}` : null,
        }
        
    };
    const response = await axios.post(url,data, requestOptions);
    return response;
}

async function unpost(url, data) {
    const token= getToken()
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data',
                accept: 'application/json',
        }
        
    };
    const response = await axios.post(url,data, requestOptions);
    return response;
}


async function dfput(url, data) {
    const token= getToken()
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
            'Authorization': token ? `${tokenBeaer} ${token}` : null,
        },
      
    };
    const response = await axios.put(url,data, requestOptions);
    return response; 
}

async function put(url, data) {
    const token= getToken()
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'multipart/form-data',
                accept: 'application/json',
                'Authorization': token ? `${tokenBeaer} ${token}` : null,
        }
        
    };
    const response = await axios.put(url,data, requestOptions);
    return response;
}

async function unput(url, data) {
    const token= getToken()
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'multipart/form-data',
                accept: 'application/json',
        }
        
    };
    const response = await axios.put(url,data, requestOptions);
    return response;
}

async function _delete(url) {
    const token= getToken()
    const requestOptions = {
        method: 'DELETE',
        headers:{
            'Authorization': token ? `${tokenBeaer} ${token}` : null,
        }
    };
    const response = await axios.delete(url, requestOptions);
    return response;
}
