import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
// @mui
import {  styled } from '@mui/material/styles';
import { Box, Link, Divider, Card, Grid, Avatar,  Typography, CardContent } from '@mui/material';
// components
import Label from '../../../components/label';
import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify';
// utils
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import { getAllCourses } from '../../../redux/slices/course';

// mock
// import COURSES from '../../../_mock/course';
//
// ----------------------------------------------------------------------
const StyledCardMedia = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)',
});

const StyledTitle = styled(Link)({
  // height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  '&:hover': { color: '#0288D1' },
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2),
}));
const StyledAvatarName = styled(Typography)(({ theme }) => ({
  zIndex: 9,
  width: 220,
  height: 20,
  position: 'absolute',
  left: theme.spacing(9),
  bottom: theme.spacing(-3),
}));

const StyledCover = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'fill',
  position: 'absolute',
});

const StyledBox = styled('div')(({ theme }) => ({
  px: theme.spacing(2),
  position: 'relative',
}));

const BoxReadmoreDesign = styled(Box)({
  bottom: 0,
  backgroundColor: '#DF3E30',
  position: 'absolute',
  width: '100%',
  borderBottomLeftRadius: '15px',
  borderBottomRightRadius: '15px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  underline:'none',
  '&:hover': { backgroundColor: '#0288D1' },
});
//
// const SHARE_OPTIONS = [
//   { icon: 'ic:baseline-facebook', url: '#' },
//   { icon: 'mdi:instagram', url: '#' },
//   { icon: 'mdi:twitter', url: '#' },
// ];
// {icon: 'eva:share-fill',url:'#'},
// ----------------------------------------------------------------------
CourseCard.propTypes = {
  course: PropTypes.object.isRequired,
};

function CourseCard({ course }) {
  const { slug, image, name, tag, introduction } = course;
  const isFeatured = true;

  const linkto = `/course/${slug}`;

  const [xstyle, setStyle] = useState('none');

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card
        sx={{ position: 'relative' }}
        onMouseEnter={(e) => {
          setStyle('flex');
        }}
        onMouseLeave={(event) => {
          setStyle('none');
        }}
      >
        {isFeatured && (
          <Label color={'error'} sx={{ top: 5, left: 5, zIndex: 9, position: 'absolute' }}>
            Featured
          </Label>
        )}
        <StyledCardMedia>
          <SvgColor
            color="paper"
            src="/assets/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute',
              color: 'background.paper',
            }}
          />
          <StyledAvatar alt={tag.name} src={tag.image} />
          <StyledAvatarName variant="caption">{tag.name}</StyledAvatarName>

          <StyledCover alt={name} src={image} />
        </StyledCardMedia>

        <CardContent sx={{ pt: 4 }}>
          <StyledTitle color="inherit" variant="subtitle2" underline="hover" component={RouterLink} to={linkto}>
            {name}
          </StyledTitle>
          <Divider sx={{ my: 1 }} />
          <StyledBox>
           <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  {introduction}
            </Typography>
            {/* <Stack spacing={1.5} direction="row" justifyContent="space-around">
              <Stack spacing={1.5} direction="row" justifyContent="flex-start">
                <Iconify icon={'ic:twotone-folder-special'} color={'#DF3E30'} width={18} />
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  {subjectCount} Lessons
                </Typography>
              </Stack>
              <Stack spacing={1.5} direction="row" justifyContent="flex-start">
                <Iconify icon={'ic:sharp-slow-motion-video'} color={'#DF3E30'} width={18} />
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  {videoCount} Videos
                </Typography>
              </Stack> 
              
            </Stack> */}
          </StyledBox>
        </CardContent>
        <BoxReadmoreDesign
         component={RouterLink}
         to={linkto}
          sx={{
            px: 3,
            py: 1.8,
            color:'#fff',            
            display: `${xstyle}`,
          }}
         
        >
          <Iconify icon={'ic:round-read-more'} width={24} sx={{ mr: 0.5 }} />
          <Typography variant="body2"  >
            Readmore
          </Typography>
          <Iconify icon={'ic:baseline-share'}  sx={{ ml:5, width: 24, mr: 0.5 }} />
        </BoxReadmoreDesign>
      </Card>
    </Grid>
  );
}

// ----------------------------------------------------------------------

export default function AllCourseWidget() {
  const dispatch = useDispatch();
  const { courses, error, isLoading } = useSelector((state) => state.course);

  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  if (!courses && isLoading) {
    return (
      <Typography variant="subtitle1" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
        Loading...
      </Typography>
    );
  }

  return (
    <Grid container spacing={3}>
      {courses.map((course, index) => (
        <CourseCard key={index} course={course} />
      ))}
    </Grid>
  );
}
