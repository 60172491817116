import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect } from 'react';
// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Link, Card, Grid, Stack, Typography } from '@mui/material';
// utils
import { LoadSkeleton } from '../../components/widegts';
import Iconify from '../../components/iconify';

// redux
import { useDispatch, useSelector } from '../../redux/store';

// data
import { getStreams } from '../../redux/slices/campus';
// ----------------------------------------------------------------------

StreamCard.propTypes = {
  stream: PropTypes.object,
  setStream: PropTypes.func,
};

function StreamCard({ stream, setStream }) {
  const { slug, title, image } = stream;

  const handleSearch = (e) => {
    setStream(slug);
  };
  return (
    <Box
      sx={{
        mx: 0.5,
        px: 2,
        my: 0.4,
        backgroundColor: slug===''?'#E52B50':'#0076CE',
        color: '#fff',
        cursor:'pointer',
        '&:hover': {
          backgroundColor: '#007791',
        },

      }}
      onClick={handleSearch}
    >
      {/* <Box sx={{ pt: '10%', position: 'relative' }}>
          <Box component={'img'} src={image} />
        </Box> */}
      <Stack spacing={2}  sx={{p:0.5}}>
        <Typography variant="subtitle2" noWrap>
          {title}
        </Typography>
      </Stack>
    </Box>
  );
}

// ----------------------------------------------------------------------
StreamWidget.propTypes = {
  setStream: PropTypes.func,
};

export default function StreamWidget({ setStream }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { streams, error, isLoading } = useSelector((state) => state.campus);

  useEffect(() => {
    dispatch(getStreams());
  }, [dispatch]);

  if (!streams && isLoading) {
    return <LoadSkeleton />;
  }

  const streamclear ={
    slug:'', title:'Clear', image:''
  }

  return (
    <Grid container spacing={0.5} sx={{ my: theme.spacing(2) }}>
      {streams.map((stream, index) => (
        <StreamCard key={index} stream={stream} setStream={setStream} />
      ))}

<StreamCard key={'stclear'} stream={streamclear} setStream={setStream} />
    </Grid>
  );
}
