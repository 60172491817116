import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
// import { useSnackbar } from 'notistack';

// @mui
import { Paper, Stack, Typography, DialogTitle } from '@mui/material';
import { DialogAnimate } from '../../components/animate';
import Iconify from '../../components/iconify';

// widgets
import ApplicationForm from './ApplicationForm';

// ----------------------------------------------------------------------
HandleApplication.propTypes = {
  title: PropTypes.string,
  courseslug: PropTypes.string,
  collegeslug: PropTypes.string,
  isclear: PropTypes.bool,
};
export default function HandleApplication({ title, courseslug = '', collegeslug = '' }) {
  const [open, setOpen] = useState(false);
  const { cmpcourseslug, cmpcollegeslug } = useParams();

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          backgroundColor: '#71E9EE',
          mx: 4,
          px: 3,
          py: 1.5,
          '&:hover': {
            backgroundColor: '#091A7A',
          },
        }}
      >
        <Stack
          direction={'row'}
          spacing={3}
          sx={{ textAlign: 'center', justifyContent: 'space-between', color: '#000', '&:hover': { color: '#fff' } }}
          onClick={handleOpenModal}
        >
          <Typography variant="body1">Apply Now</Typography>
          <Iconify icon={'lucide:send'} width={24} />
        </Stack>
      </Paper>
      <DialogAnimate open={open} onClose={handleCloseModal}>
        <DialogTitle>Apply for {title}</DialogTitle>
        <ApplicationForm
          cmpcourseslug={courseslug ?? cmpcourseslug}
          cmpcollegeslug={collegeslug ?? cmpcollegeslug}
          onCancel={handleCloseModal}
        />
      </DialogAnimate>
    </>
  );
}
