import PropTypes from 'prop-types';
// @mui
import { Box, Card, Paper, Typography, CardHeader, CardContent } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';

// utils
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const featurelist=[
  {
    name: 'Professional Mentors',
    value: 323234,
    icon: 'line-md:emoji-grin',
    color:'#1877F2',
  }, // line-md:account
  {
    name: 'Video Lessons',
    value: 341212,
    icon: 'line-md:play-twotone',
    color:'#DF3E30',
  },
  {
    name: 'Previous Questions',
    value: 411213,
    icon: 'line-md:question-circle',
    color:'#006097',
  },
  {
    name: '100% Result Guaranteed',
    value: 443232,
    icon: 'line-md:clipboard-check-twotone',
     color:'#1C9CEA',
  },
];
// ----------------------------------------------------------------------
FeatureWidget.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function FeatureWidget({ title, subheader,  ...other }) {
  const isMobile = useResponsive('down', 'md');

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <CardContent>
        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          {featurelist.map((site) => (
            <Paper key={site.name} variant="outlined" sx={{ py: 4.5, textAlign: 'center' }}>
              <Box sx={{ mb: 0.5 }}><Iconify icon={site.icon} color={site.color} width={48} /></Box>

              {/* <Typography variant="body2" sx={{ color: 'text.secondary' }} >{fShortenNumber(site.value)}</Typography> */}

              <Typography variant={isMobile?"body2":"h6"} >
                {site.name}
              </Typography>
              
            </Paper>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}

