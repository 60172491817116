import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Grid, Container, Typography } from '@mui/material';

// APP Name
import { AppName } from '../../config';
// ----------------------------------------------------------------------
// components
import { SearchWidget, FilterWidget, SearchResultWidget, StreamWidget } from '../../sections/@campus';

// redux
import { useDispatch, useSelector } from '../../redux/store';

// data
import { getCampusSearch } from '../../redux/slices/campus';

// ----------------------------------------------------------------------

export default function SearchPage() {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [stream, setStream] = useState('');
  const [area, setArea]=  useState('');
  const [page, setPage] = useState(0);
  const { searchresult, error, isLoading } = useSelector((state) => state.campus);

  useEffect(() => {
    dispatch(getCampusSearch(searchText, area, stream, page));
  }, [dispatch, searchText,area, stream, page]);

  return (
    <>
      <Helmet>
        <title> Campus Search | {AppName} </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <StreamWidget setStream={setStream}/>
            <SearchWidget setSearchText={setSearchText} setArea={setArea} />
            <SearchResultWidget result={searchresult} searchText={searchText} />
            
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <FilterWidget />
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
