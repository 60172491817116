import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect } from 'react';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Input, TextField, Button, IconButton, InputAdornment } from '@mui/material';
// utils
import { LoadSkeleton } from '../../components/widegts';
import Iconify from '../../components/iconify';
import { bgBlur } from '../../utils/cssStyles';

// redux
import { useDispatch, useSelector } from '../../redux/store';

// data
import { getRegions } from '../../redux/slices/campus';

const HEADER_MOBILE = 174;
const HEADER_DESKTOP = 82;
// ----------------------------------------------------------------------

const StyledSearchbar = styled('div')(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  // top: 0,
  // left: 0,
  // zIndex: 99,
  width: '100%',
  display: 'block',
  // position: 'absolute',
  alignItems: 'center',
  height: HEADER_MOBILE,
  padding: theme.spacing(1, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 4),
    display: 'flex',
  },
}));
// ----------------------------------------------------------------------

SearchWidget.propTypes = {
  setSearchText: PropTypes.func,
  setArea: PropTypes.func,
  currentArea: PropTypes.number,
};

export default function SearchWidget({ setSearchText, setArea }) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { regions, error, isLoading } = useSelector((state) => state.campus);

  useEffect(() => {
    dispatch(getRegions());
  }, [dispatch]);

  const handleSetArea = (e) => {
    setArea(e.target.value);
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  if (!regions && isLoading) {
    return <LoadSkeleton />;
  }

  const getLevel = (option) => {
    let sp="";
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < option.level; i++) {
      sp += "  ";
    }
    return  `${sp}${option.title}`;
  };

  return (
    <StyledSearchbar>
      <Input
        autoFocus
        fullWidth
        disableUnderline
        placeholder="Search…"
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        }
        sx={{ mr: 1, fontWeight: 'fontWeightBold', mt:{xs:1.5, md:0}}}
        onChange={handleSearch}
      />
      <TextField
        select
        fullWidth
        onChange={handleSetArea}
        size="small"
        SelectProps={{ native: true }}
        sx={{mr: 1, fontWeight: 'fontWeightBold', my:{xs:1.5, md:0}}}
      >
        {regions.map((option) => (
          <option key={option.id} value={option.id}>
            {getLevel(option)}
          </option>
        ))}
      </TextField>

      <Button variant="contained" fullWidth >Search</Button>
    </StyledSearchbar>
  );
}

/*

Inventory - 
Accounts - 
Sales - 
Purchase - 
Cash - 

Cafteria - 
Monitoring - 

*/