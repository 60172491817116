import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'react';
// material
import { alpha, useTheme } from '@mui/material/styles';
import { Grid, Box, Card, Stack, Avatar, Divider, Typography} from '@mui/material';
// utils
//
import { fToNow } from '../../../utils/formatTime';
import { CarouselControlsArrowsBasic2 } from '../../../components/carousel/controls';

import { useDispatch, useSelector } from '../../../redux/store';
import { getTestimonials } from '../../../redux/slices/course';

// ----------------------------------------------------------------------

// const CAROUSELS = [...Array(5)].map((_, index) => {
//   const setIndex = index + 1;
//   return {
//     title: faker.company.name(),
//     description: faker.lorem.paragraphs(),
//     image: `/assets/images/products/product_${setIndex}.jpg`,
//   };
// });

CarouselItemTest.propTypes = {
  item: PropTypes.object
};

function CarouselItemTest({ item }) {
  const { image, title } = item;

  return (
    <Box
      component="img"
      alt={title}
      src={image}
      sx={{ width: '100%', height: 480, objectFit: 'cover' }}
    />
  );
}


CarouselBasic1.propTypes = {
  carousels: PropTypes.array,
};


function CarouselBasic1({carousels}) {
  const theme = useTheme();
  const carouselRef = useRef();
 
  const [currentIndex, setCurrentIndex] = useState(
    theme.direction === 'rtl' ? carousels.length - 1 : 0
  );

  const settings = {
    speed: 500,
    dots: false,
    arrows: false,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === 'rtl'),
    beforeChange: (current, next) => setCurrentIndex(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 900,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 580,
        settings: { slidesToShow: 1, centerPadding: '0' },
      },
    ],
  };

  const handlePrevious = () => {
    carouselRef.current.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current.slickNext();
  };

  return (
    <Card>
      <Slider ref={carouselRef} {...settings}>
        {carousels.map((item, index) => (
          <CarouselItem key={index} item={item} />
        ))}
      </Slider>
      <CarouselControlsArrowsBasic2 onNext={handleNext} onPrevious={handlePrevious} />
    </Card>
  );
}

CarouselItem.propTypes = {
  item: PropTypes.object,
};

function CarouselItem({ item }) {
  const theme = useTheme();
  const {  body, user, addedAt } = item;
  const { name,  avatar } = user;

  return (
    <>
    <Stack
      direction="column"
      sx={{
        px: {xs:theme.spacing(4),md: theme.spacing(10)},
        py:theme.spacing(4),
        position: 'relative',
        zIndex: 0,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={{
          xs: 0.5,
          sm: 1,
        }}
        sx={{ mb: theme.spacing(3) }}
      >
        <Avatar
          alt={name}
          src={avatar}
          sx={{
            width: 60,
            height: 60,
          }}
        />
        <Stack
          direction="column"
          sx={{
            px: theme.spacing(0.5),
            py: 0.75,
            position: 'relative',
            zIndex: 0,
          }}
        >
          <Typography variant="body1" sx={{ color: (theme) => alpha(theme.palette.grey[900], 0.72) }}>
            {name}
          </Typography>
          <Typography gutterBottom variant="caption" sx={{ color: 'text.disabled', display: 'block' }}>
            {fToNow(addedAt)}
          </Typography>
        </Stack>
      </Stack>

      <Divider sx={{ borderStyle: 'dashed', mb: theme.spacing(3) }} />

      <Typography variant="subtitle1" sx={{ color: (theme) => alpha(theme.palette.grey[900], 0.72) }}>
        {body}
      </Typography>
      
    </Stack>
    </>
  );
}


export default function FeedbackWidget() {
  const dispatch = useDispatch();

  const { testimonials, error, isLoading } = useSelector((state) => state.course);

  
  useEffect(() => {
    dispatch(getTestimonials());
  }, [dispatch]);

  const hasItem = testimonials && testimonials.length>0;

  if (!hasItem && isLoading){
    return (
      <Typography variant="subtitle1" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
        Loading...
      </Typography>
    );
  }

  if (!hasItem && error){
    return (
      <Typography variant="subtitle1" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
        Error...
      </Typography>
    );
  }
// Success Stories that have made and Impact Hear from young individuals who have taken the path to success. With the right guidance and training from ASAP, these individuals have been able to become one step closer to their dreams.
  return ( 
  <Grid item xs={12} sx={{ mt: 5 }}>
    <Typography variant="h3" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
     TESTIMONIALS
    </Typography>
    <Typography variant="subtitle1" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
    Success Stories that have made and Impact
    </Typography>
    <Typography variant="subtitle1" sx={{ color: '#000', display: 'block', textAlign: 'center', mb:3 }}>
    Hear from young individuals who have taken the path to success. With the right guidance and training from ASAP, these individuals have been able to become one step closer to their dreams.
    </Typography>
    <CarouselBasic1  carousels={testimonials}/>
    </Grid>);

}