
// AUTH
export const LOGIN_TOKEN_URL= "/api/auth/token/";
export const LOGIN_TOKEN_REFRESH_URL= "/api/token/refresh/";

// USER
export const USER_PROFILE_URL= "/api/auth/user";

// WEB BASE URL
// CMS
export const CMS_URLS={
    cms:'/api/wb/cms/contents/', // + type
    faq:'/api/wb/cms/faq/', 
}
// Home
export const HOME_URLS={
    banners:'/api/wb/basic/banners/web/', // + type
    
}

// COURSE
export const COURSE_URLS={
    list:'/api/wb/basic/courses/',
    get:'/api/wb/basic/course/', // +slug
}

export const TESTIMONIAL_URLS={
    list:'/api/wb/basic/testimonials/',
}

export const STUDENT_WALL_URLS={
    list:'/api/wb/basic/students/',
}

export const TEAM_URLS={
    list:'/api/wb/basic/team/',
}

export const SERVICE_URLS={
    list:'/api/wb/basic/services/',
    get:'/api/wb/basic/service/', // +slug
}

export const CAMPUS_URLS={
    search:'/api/wb/cmp/campus/search/?q=',
    college:'/api/wb/cmp/college/get/',
    course:'/api/wb/cmp/course/get/',
    streams:'/api/wb/cmp/stream/list/',
    regions:'/api/wb/cmp/region/list/',
}
