import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {  useEffect } from 'react';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Button, Link, Card, Grid, Typography } from '@mui/material';

import Markdown from '../../../components/markdown';
import { LoadSkeleton} from '../../../components/widegts';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import { getServices } from '../../../redux/slices/course';

//
// ----------------------------------------------------------------------


const StyledTitle = styled(Link)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});


// ----------------------------------------------------------------------
ServiceCard.propTypes = {
  service: PropTypes.object.isRequired,
  index: PropTypes.number,
};

function ServiceCard({ service, index }) {
  const theme = useTheme();
  const {slug, title, intro,body } = service;
  const latestCourseLarge = index === 0;
  const linkto=`/service/${slug}`;
  

  return (
    <Grid item xs={12} sm={latestCourseLarge ? 12 : 6} >
      <Card sx={{ position: 'relative', px:theme.spacing(3),  py:theme.spacing(6),}}>
      <StyledTitle
            color="inherit"
            underline='none'
            variant="h4"
            sx={{
            
              textAlign:'center',
              ...(latestCourseLarge && { typography: 'h3', height: 60 }),
               
            }}
          >
            { title}
          </StyledTitle>
          {latestCourseLarge ? 
          <Markdown children={body} />
          :
          <Typography  variant="subtitle2" sx={{py:theme.spacing(5),px: theme.spacing(5)}} >{latestCourseLarge ? intro : intro.slice(0,200)}
         </Typography>
}
 <Button to={linkto} size="small" variant="contained" component={RouterLink} sx={{mx:theme.spacing(2)}}>
          Read more
          </Button>
          
      </Card>
    </Grid>
  );
}

// ----------------------------------------------------------------------

export default function ServiceWidget() {
  const dispatch = useDispatch();
  const { services, error, isLoading } = useSelector((state) => state.course);

  useEffect(() => {
    dispatch(getServices());
  }, [dispatch]);

  

  if(!services && isLoading){
    return (
     <LoadSkeleton/>
    );
  }

  return (
    <Grid container spacing={3} sx={{py:5}}>
      {services.map((service, index) => (
        <ServiceCard  key={index} service={service} index={index} />
      ))}
    </Grid>
  );
}
