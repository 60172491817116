import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Box, Grid, Container, Typography } from '@mui/material';
// components
import {
  HeroWidget,
  AboutWidget,
  CourseWidget,
  FeedbackWidget,
  FeatureWidget,
  FeatureHWidget,
  PassoutWidget,
  ContactWidget,
  ContactFormWidget,
  FunWidget,
  BenfitWidget,
} from '../../sections/web/home';

import { TeamWidget } from '../../sections/web/teams';

// APP Name
import { AppName } from '../../config';

const CenterBox = styled(Box)({
  width: '100%',
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
// ----------------------------------------------------------------------

export default function HomePage() {
  return (
    <>
      <Helmet>
        <title> Home| {AppName} </title>
      </Helmet>
      <HeroWidget sx={{ px: 0, mx: 0 }} />

      <Container maxWidth="xl">
        <Grid item xs={12}>
          <AboutWidget />
        </Grid>

        <Grid item xs={12} sx={{ mx: { xs: 1, md: 15 } }}>
          <Typography variant="subtitle1" sx={{ color: '#DF3E30', display: 'block', textAlign: 'center' }}>
            Checkout New Courses
          </Typography>
          <Typography variant="h3" sx={{ color: 'colors.black', display: 'block', textAlign: 'center', mb: 3 }}>
            Explore Courses
          </Typography>
          <CourseWidget />
          <CenterBox>
            <Box sx={{ flexGrow: 1 }} />
            <Button to="/courses" size="large" variant="contained" component={RouterLink} sx={{ my: 3 }}>
              VIEW ALL COURSES
            </Button>
            <Box sx={{ flexGrow: 1 }} />
          </CenterBox>
        </Grid>

        <FeatureHWidget />

        <PassoutWidget />

        {/* <FunWidget /> */}
        <Grid item xs={12}>
          <BenfitWidget />
        </Grid>

        <Grid item xs={12} sx={{ mx: { xs: 1, md: 15 }, mb: 2 }}>
          <Typography variant="h3" sx={{ color: 'colors.black', display: 'block', textAlign: 'center', mb: 3 }}>
            Our Team
          </Typography>
          <TeamWidget isSlice />
        </Grid>

        <FeedbackWidget />

        <Grid item xs={12}>
          <ContactFormWidget />
        </Grid>
      </Container>
    </>
  );
}
