import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Container, Typography } from '@mui/material';
// components
import {
  FeatureWidget,
  ContactWidget
} from '../../sections/web/home';


import {ServiceWidget } from '../../sections/web/service';

// APP Name
import { AppName } from '../../config';

// ----------------------------------------------------------------------

export default function ServicePage() {
  return (
    <>
      <Helmet>
        <title> Services | {AppName} </title>
      </Helmet>
       <Container maxWidth="xl">
        <Grid container spacing={3}>

    
          <Grid item xs={12}>
         
          <Typography variant="subtitle1" sx={{my:2, color: '#000', display: 'block', textAlign: 'center' }}>
             The best choice for your career
            </Typography>
            <Typography variant="h3" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
             Our Services
            </Typography>
            <ServiceWidget />
          </Grid>

       
          <Grid item xs={12}>
            <FeatureWidget
              title="Main Feature"
              subheader="Features we provide"
              />
          </Grid>

    

          <Grid item xs={12} >
            <ContactWidget />
          </Grid>

        </Grid>
        </Container>
    </>
  );
}
