import PropTypes from 'prop-types';
// material
import { alpha,  styled } from '@mui/material/styles';
import {  Typography } from '@mui/material';

// ----------------------------------------------------------------------


const RootStyle = styled('div')(({ theme }) => ({
  height: 480,
  position: 'relative',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  [theme.breakpoints.up('md')]: {
    height: 'auto',
    paddingTop: 'calc(100% * 9 / 16)'
  },
  '&:before': {
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: alpha(theme.palette.grey[900], 0.72)
  }
}));

const TitleStyle = styled(Typography)(({ theme }) => ({
  top: 0,
  zIndex: 10,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(10)
  }
}));



const CoverImgStyle = styled('img')({
  top: 0,
  zIndex: 8,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// ----------------------------------------------------------------------

ServiceHero.propTypes = {
  service: PropTypes.object.isRequired
};

export default function ServiceHero({ service, ...other }) {
  const { image, title } = service;
 
  return (
    <RootStyle {...other}>
      <CoverImgStyle alt="post cover" src={image} />

      <TitleStyle variant="h2" component="h1">
        {title}
      </TitleStyle>

   
    </RootStyle>
  );
}
