// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box,Grid, Stack, Typography } from '@mui/material';
// utils
//
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledCardMedia = styled('div')(({ theme }) => ({
  position: 'relative',
  paddingTop: 'calc(100% * 2 / 5)',

  [theme.breakpoints.down('sm')]: {
    paddingTop: 'calc(100% * 4 / 1)',
  },
}));

const StyledCover = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',

});
const FooterStyle = styled('div')(({ theme }) => ({
  top: 0,
  zIndex: 10,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'flex-start',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  justifyContent: 'space-between',
}));
// ----------------------------------------------------------------------
const FEATURELIST = [
  {
    name: 'Professional Mentors',
    count: 1200,
    icon: 'line-md:emoji-grin',
  }, 
  {
    name: 'Video Lessons',
    count: 200,
    icon: 'dashicons:playlist-video',
  },
  {
    name: 'Previous Questions',
    count: 12000,
    icon: 'line-md:question-circle',
  },
];

// ----------------------------------------------------------------------
export default function FunWidget() {
  const theme = useTheme();
  const cover = 'assets/images/asap/banner_02.jpg';

  return (
    <Grid item xs={12} sx={{ mb: theme.spacing(10) }}>
      <Box sx={{ position: 'relative', mx: 0 }}>
        <StyledCardMedia
          sx={{
            '&:after': {
              top: 0,
              px: 0,
              content: "''",
              width: '100%',
              height: '100%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }}
        >
          <StyledCover alt={'title'} src={cover} />
        </StyledCardMedia>

        <FooterStyle>
          <Grid container spacing={theme.spacing(2)}>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'block', alignItems: 'left', py: theme.spacing(2) }}>
                <Typography variant="caption" sx={{ color: 'grey.500' }}>
                  Get Free consultation
                </Typography>
                <Typography variant="h3" wrap={'true'} sx={{ color: 'common.white', my: theme.spacing(3) }}>
                Make the right decision & Access to 15's of Courses
                </Typography>
                <Typography variant="body2" wrap={'true'} sx={{ color: 'common.white', my: theme.spacing(3) }}>
                  Please provide us with your contact information, brief description of what you are looking for, so we
                  can reach out to you and schedule a time for the consultation.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  mt: { xs: 1, md: 8 },
                  mx: { xs: 1, md: 2 },
                  display: { xs: 'block', md: 'grid' },
                  gap: 1,
                  gridTemplateColumns: 'repeat(3, 1fr)',
                }}
              >
                {FEATURELIST.map((site) => (
                  <Box key={site.icon} sx={{borderRadius:'15px', backgroundColor:'#000', mx:2, mb:2, py:4}}>
                  <Stack spacing={1.5} direction="column" alignItems="center" justifyContent="center">
                    <Iconify icon={site.icon} color={'#DF3E30'} width={64} />

                      <Typography variant={'h4'}>{site.count}</Typography>
                      <Typography variant="body1" sx={{ color: 'text.secondary', textAlign:'center', width:'100px' }}>
                        {site.name}
                      </Typography>
                  
                  </Stack>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </FooterStyle>
      </Box>
    </Grid>
  );
}
