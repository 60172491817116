// @mui
import { Box, Stack, Paper, Typography } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';

// utils
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const featurelist = [
  {
    name: 'Professional Mentors',
    subtitle: 'Professional Mentors',
    icon: 'line-md:emoji-grin',
    color: '#1877F2',
  }, // line-md:account
  {
    name: 'Video Lessons',
    subtitle: 'Professional Mentors',
    icon: 'line-md:play-twotone',
    color: '#DF3E30',
  },
  {
    name: 'Previous Questions',
    subtitle: 'Professional Mentors',
    icon: 'line-md:question-circle',
    color: '#006097',
  },
];
// ----------------------------------------------------------------------

export default function FeatureHWidget() {
  const isMobile = useResponsive('down', 'md');

  return (
    <Box
      sx={{
        mt: 3,
        mx: { xs: 1, md: 20 },
        display: {xs:'block',md:'grid'},
        gap: 2,
        gridTemplateColumns: 'repeat(3, 1fr)',
      }}
    >
      {featurelist.map((site) => (
        <Paper
          key={site.name}
          variant="outlined"
          sx={{ py: 4.5, px: 2,mb:2, '&:hover': { borderBottom: 8, borderBlockColor: site.color, boxShadow: 1, } }}
        >
          <Stack spacing={1.5} direction="row" justifyContent="space-around">
            <Iconify icon={site.icon} color={site.color} width={48} />

            <Stack sx={{ textAlign: 'center', mx: 3 }}>
              <Typography variant={isMobile ? 'body2' : 'h6'}>{site.name}</Typography>
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                {site.subtitle}
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      ))}
    </Box>
  );
}
