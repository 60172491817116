import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
// APP Name
import { AppName } from '../../config';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '90vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(5, 0),
}));
// ----------------------------------------------------------------------
Widget404.propTypes = {
  item: PropTypes.string,
  subitem: PropTypes.string,
};
// ----------------------------------------------------------------------

export default function Widget404({item,subitem}) {
  return (
    <>
      <Helmet>
        <title> 404 - Not Found | {AppName} </title>
      </Helmet>

      <Container sx={{pt:0}}>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center',mt:0,pt:0 }}>
          <Typography variant="h3" paragraph>
            Sorry, {item} not found!
          </Typography>
          <Typography variant="h6" paragraph>
            {subitem || ''}
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your
            spelling.
          </Typography>

          <Box
            component="img"
            src="/assets/illustrations/illustration_404.svg"
            sx={{ height: { xs: 120, sm: 220 }, mx: 'auto', my: { xs: 5, sm: 6 } }}
          />

          <Button to="/" size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button>
        </StyledContent>
      </Container>
    </>
  );
}
