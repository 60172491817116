
export const SOCIALS = [
    { name: 'FaceBook', icon: 'eva:facebook-fill' },
    { name: 'Google', icon: 'eva:google-fill' },
    { name: 'Linkedin', icon: 'eva:linkedin-fill' },
    { name: 'Twitter', icon: 'eva:twitter-fill' },
  ];
  
 export const LINKS = [
    {
      headline: 'Sitemap',
      children: [
        { name: 'Home', href: '/' },
        { name: 'Campus', href: '/campus'},
        { name: 'About us', href: '/about-us' },
        { name: 'Contact us', href: '/contact-us' },
        { name: 'Services', href: '/sevices' },
        { name: 'Team', href: '/team' },
        { name: 'FAQs', href: '#' },
        { name: 'Terms and Condition', href: '/terms' },
        { name: 'Privacy Policy', href: '/privacy' },
      ],
    },
  ];
  
  export const CONATCTLIST = [
    {
      flag: 'flag:ae-4x3',
      name: 'United Arab Emirates',
      color: '#1877F2',
      address: 'Shifa AL Jazeera Building, Al Nakheel - RAK ',
      mobile: '971 54 349 4801',
      email: 'info@asapuae.com',
      location: 'info@asapuae.com',
    },
  
    {
      flag: 'flag:in-4x3',
      name: 'India',
      color: '#1877F2',
      address: ' Opposite Government Technical - School Kuttipuram, Tirur Road, Kuttippuram Kerala, 679 571',
      mobile: '91 75 5880 8704',
      email: 'info@asapuae.com',
      location: 'info@asapuae.com',
    },
  ];
  
export const LINK_DOWNLOADS = [
    {
      name: 'Android',
      logo: '/assets/images/store/playstore.png',
      href: 'https://play.google.com/store/apps/details?id=com.asapuae.garliksofts',
    },
    { name: 'IOS', logo: '/assets/images/store/appstore.png', href: 'https://apps.apple.com/app/id1668307429' },
  ];