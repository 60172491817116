// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Link, Card, Grid, Stack, Typography } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// utils
//
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AboutWidget() {
  const isDesktop = useResponsive('up', 'md');
  return (
    <Grid container spacing={3} sx={{ py: 5 }}>
      <Grid item xs={12} md={4} sx={{ px: 5, mt: 2, mb:4 }}>
        <Box
          component="img"
          src="/assets/images/asap/about.png"
          sx={{ height: 400, cursor: 'pointer', objectFit: 'scale-down' ,mx:2}}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography variant="h3" sx={{ py: 5, color: '#000', display: 'block', textAlign: 'center' }}>
          About ASAP UAE
        </Typography>
        <Typography variant="body1" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
        ASAP MARKETING AND STUDY CONSULTANCY is an independent and dynamic training and service provider  established in 2017 .
ASAP Marketing and study consultancy is exclusively for the career development of Health care professionals. We  deliver the complete assistance for acquiring the better success and opportunities in the health care sector in GCC Countries namely  DOH,DHA,MOH,OMSB.QCHP.SCHC.NHRA along with others.
  Our professional and well qualified acquaint team provides complete and prompt guidance along with unique and better way  of Teaching as well the complete process of Document verification along with timebound cost effective service to Healthcare Professionals.
        </Typography>
        <Typography variant="body1" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
          With our comprehensive approach to exam preparation, we have helped countless students succeed in their
          professional endeavors. Whether you are looking to advance in your current career or take on a new challenge,
          ASAP Marketing and Studies Consultancy is here to help you succeed.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{my:3}}>
        <Stack
          direction={isDesktop ? 'row' : 'column'}
          alignItems="center"

          spacing={{
            xs: 0.5,
            sm: 1,
          }}
          sx={{ mx: 2 }}
        >
         
          <Card sx={{p:3}}>
            <Typography variant="h4" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
              Mission
            </Typography>
            <Typography variant="body1" sx={{ py:5, color: '#000', display: 'block', textAlign: 'center' }}>
            Our mission is To provide a High Quality Training and support towards Achieve and to meet the ever evolving social and Economic needs for our clients and candidates through best practice.
            </Typography>
          </Card>
          <Card sx={{p:3}}>
            <Typography variant="h4" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
              Vision
            </Typography>
            <Typography variant="body1" sx={{  py:5, color: '#000', display: 'block', textAlign: 'center' }}>
              To be a Forefront service Provider in the heart and mind of Health Professionals, to feel heard appreciated and valued ,for the process to  easy and without friction
            </Typography>
          </Card>
        </Stack>
      </Grid>
    </Grid>
  );
}
