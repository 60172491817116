// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
// utils

import { ConsultForm } from '../contact';

// ----------------------------------------------------------------------

const StyledCardMedia = styled('div')(({ theme }) => ({
  position: 'relative',
  paddingTop: 'calc(100% * 2 / 5)',

  [theme.breakpoints.down('sm')]: {
    paddingTop: 'calc(100% * 5 / 1.9)',
  },

}));

const StyledCover = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  
});
const FooterStyle = styled('div')(({ theme }) => ({
  top: 0,
  zIndex: 10,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'flex-start',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  justifyContent: 'space-between',
}));
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
export default function ContactFormWidget() {
  const theme=useTheme();
  const cover = 'assets/images/asap/banner_02.jpg';

  return (
    <Grid item xs={12} sx={{ mb: { xs:theme.spacing(10),sm:theme.spacing(80), md:theme.spacing(2)} }}>
      <Box sx={{ position: 'relative', mx: 0 }}>
        <StyledCardMedia
          sx={{
            '&:after': {
              top: 0,
              px: 0,
              content: "''",
              width: '100%',
              height: '100%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }}
        >
          <StyledCover alt={'title'} src={cover} />
        </StyledCardMedia>

        <FooterStyle>
          <Grid container spacing={theme.spacing(2)}>
            <Grid item xs={12} md={7}>
              <Box sx={{ display: 'block', alignItems: 'left', py: theme.spacing(2) }}>
                <Typography variant="caption" sx={{ color: 'grey.500' }}>
                  Get Free consultation
                </Typography>
                <Typography variant="h3" wrap={'true'} sx={{ color: 'common.white', my: theme.spacing(3) }}>
                ASAP  helps you excel through a comprehensive program
                </Typography>
                <Typography variant="body2" wrap={'true'} sx={{ color: 'common.white', my: theme.spacing(3) }}>
                  Please provide us with your contact information, brief description of what you are looking for, so we
                  can reach out to you and schedule a time for the consultation.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box sx={{ display: 'block', alignItems: 'left', p:{xs:0,md:theme.spacing(3)}}}>
                <ConsultForm title='Fill Your Application'/>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
            <Box sx={{ mb: 0.5 }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 0.5 }} />
            </Grid>
          </Grid>
        </FooterStyle>
      </Box>
    </Grid>
  );
}
