import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Button,Stack, Grid, Typography } from '@mui/material';

// utils
import Iconify from '../../../components/iconify';
// ----------------------------------------------------------------------
const BoxDesign = styled('div')(({ theme }) => ({
  top: theme.spacing(3),
  left: theme.spacing(-3),
  backgroundColor: '#DF3E30',
  position: 'absolute',
  width: theme.spacing(3),
  height: '86%',
  borderStartStartRadius: '15px',
  borderEndStartRadius: '15px',
  '&:hover': {
    backgroundColor: '#1877F2',
  },
}));

// ----------------------------------------------------------------------
const LISTIMG = [
  { id: 1, url: 'assets/images/asap/about_01.jpg' },
  { id: 2, url: 'assets/images/asap/about_02.jpg' },
];

const LISTFEATURES = [
  { id: 1, body: 'Well experienced trainers.' },
  { id: 2, body: 'Practical tips to help crack job interviews.' },
  { id: 3, body: 'Project implementation guidance and Course Meterials.' },
  { id: 4, body: 'Moderate fees and flexible timing.' },
  { id: 5, body: 'Online classes available.' },
];
// ph:circle-wavy-check-bold
// ----------------------------------------------------------------------

export default function BenfitWidget() {
  return (
    <Grid container spacing={3} sx={{ my: 3 }}>
   
      <Grid item xs={12} md={6}>
        <Typography variant="subtitle2" sx={{ color: '#DF3E30', display: 'block', textAlign: 'left' }}>
        Why Choose Us?
        </Typography>
        <Typography variant="h2" component={'h1'} sx={{ color: '#000', textAlign: 'left', my: 4 }}>
        Benefits of Learning from asap marketing and studies consulatancy.
        </Typography>
        <Typography variant="body1" sx={{ color: '#000', display: 'block', textAlign: 'left', textJustify: 'auto' }}>
          ASAP Marketing and Studies Consultancy has mentored thousands of professionals in varied fields, helped them reach their goals and provided outstanding career support to these professionals. Apart from quality education and training, We Provide following facilities :
        </Typography>
        <Box sx={{px:2,my:4}}>
          {LISTFEATURES.map((lst, ) => (
            <Stack key={lst.id} spacing={1.5} direction="row" justifyContent="flex-start" mb={1.5}>
              <Iconify icon={'lucide:check-circle'} color={'#DF3E30'} width={24} />

              <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                {lst.body}
              </Typography>
            </Stack>
          ))}
        </Box>

      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            display: 'grid',
            gap: 0.8,
            mx: {xs:3,md:10},
            gridTemplateColumns: 'repeat(2, 1fr)',
            position: 'relative',
          }}
        >
          <BoxDesign />
          {LISTIMG.map((listImg) => (
            <Box key={listImg.id} sx={{ height: '350px', position: 'relative', borderRadius: '15px' }}>
              <Box
                component="img"
                src={listImg.url}
                sx={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  cursor: 'pointer',
                  objectFit: 'cover',
                  borderRadius: '15px',
                  '&:hover': { transform: 'scale(1.25)', transitionDuration: '3s' },
                }}
              />
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
}
