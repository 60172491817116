import PropTypes from 'prop-types';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Card, Stack, Grid, Typography, Divider } from '@mui/material';
// utils
import Iconify from '../../../components/iconify';
import { Gmap } from '../../../components/map';


const StyledTitle = styled(Typography)({
  pt: 1,
  height: 32,
  width: 250,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});

const StyledAddress = styled(Typography)({
  pt: 1,
  height: 88,
  width: 250,
  overflow: 'hidden',
  WebkitLineClamp: 4,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});
// ----------------------------------------------------------------------
const CONATCTLIST = [
  {
    flag: 'flag:ae-4x3',
    name: 'United Arab Emirates',
    color: '#1877F2',
    address: 'Shifa AL Jazeera Building, Al Nakheel - RAK ',
    mobile: '971 54 349 4801',
    email: 'info@asapuae.com',
    location: 'info@asapuae.com',
  },

  {
    flag: 'flag:in-4x3',
    name: 'India',
    color: '#1877F2',
    address: ' Opposite Government Technical - School Kuttipuram, Tirur Road, Kuttippuram Kerala, Pin 679 571',
    mobile: '91 75 5880 8704',
    email: 'info@asapuae.com',
    location: 'info@asapuae.com',
  },
];


// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
RenderIcon.propTypes = {
  icon: PropTypes.string,
};

function RenderIcon({ icon }) {
  return <Iconify icon={icon} color="#999999" width={32} height={32} sx={{ mx: 5 }} />;
}
// ----------------------------------------------------------------------
ConatctHero.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function ConatctHero({ title, ...other }) {
  const theme = useTheme();

  return (
    <Box {...other}>
      <Typography variant="h4" sx={{ textAlign: 'center', py: 5 }}>
        {title}
      </Typography>

      <Grid container spacing={4} sx={{ mb: theme.spacing(5) }}>
        {CONATCTLIST.map((site) => (
          <Grid item xs={12} md={6} lg={6} key={site.name} sx={{ textAlign: 'start' }}>
            <Card sx={{ mt: theme.spacing(3.75) }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                mb={5}
                sx={{ px: theme.spacing(3.75), pt: theme.spacing(5) }}
              >
                <Iconify icon={site.flag} color="#DF3E30" width={32} />
                <Typography variant="h6" sx={{ml:theme.spacing(3)}}>{site.name}</Typography>
              </Stack>
              <Divider sx={{ borderStyle: 'dashed', mb: theme.spacing(3) }} />
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="start"
                mb={5}
                sx={{ px: theme.spacing(3) }}
              >
                <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" sx={{ mb: 2 }}>
                  <RenderIcon icon={'mdi:address-marker-outline'} />
                  <StyledAddress flexWrap variant="body2" sx={{pr:1}}>{site.address}</StyledAddress>
                </Stack>
                <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" sx={{ mb: 2 }}>
                  <RenderIcon icon={'ic:round-call'} />
                  <StyledTitle variant="body2">+{site.mobile}</StyledTitle>
                </Stack>

                <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" >
                  <RenderIcon icon={'ic:round-mail-outline'} />
                  <StyledTitle variant="body2">{site.email}</StyledTitle>
                </Stack>
              </Stack>
            </Card>

          </Grid>
        ))}
        
      </Grid>
    </Box>
  );
}
