import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';
// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Card, Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
// utils
//
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledCardMedia = styled('div')(({ theme }) => ({
  position: 'relative',
  paddingTop: 'calc(100% * 2 / 5)',

  [theme.breakpoints.down('sm')]: {
    paddingTop: 'calc(100% * 4 / 1)',
  },
}));

// ----------------------------------------------------------------------
const FEATURELIST = [
  {
    name: 'Professional Mentors',
    count: 1200,
    icon: 'line-md:emoji-grin',
  },
  {
    name: 'Video Lessons',
    count: 200,
    icon: 'dashicons:playlist-video',
  },
  {
    name: 'Previous Questions',
    count: 12000,
    icon: 'line-md:question-circle',
  },
];

// ----------------------------------------------------------------------
CariculamCard.propTypes = {
  subject: PropTypes.object,
};

function CariculamCard({ subject }) {
  const theme = useTheme();

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
        <Accordion key={subject.slug} expanded={expanded === subject.slug} onChange={handleChange(subject.slug)} sx={{mb:3}}>
          <AccordionSummary
            expandIcon={<Iconify icon={'eva:arrow-ios-downward-fill'} sx={{ width: 20, height: 20 }} />}
          >
            <Typography variant="subtitle1" sx={{ width: '90%', flexShrink: 0 }}>
              {subject.title} 
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Typography variant="body1" sx={{ width: '90%', flexShrink: 0 }}>
              {subject.introduction}
            </Typography>
          </AccordionDetails>
        </Accordion>
      
  );
}


// ----------------------------------------------------------------------
CourseCariculam.propTypes = {
  subjects: PropTypes.array,
};

export default function CourseCariculam({ subjects, ...other }) {
  const theme = useTheme();

 
  return (
    <Grid item {...other} xs={12}>
      {subjects.map((item) => (
        <CariculamCard key={item.slug} subject={item} />
      ))}
    </Grid>
  );
}
