/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Paper, Link, Card, Grid, Stack, Divider, Typography } from '@mui/material';
// components
import { Widget404 } from '../../components/widegts';
import Label from '../../components/label';
import Markdown from '../../components/markdown/Markdown';
// utils
//
import Iconify from '../../components/iconify';
import CourseListWidget from './CourseListWidget';
import ImageListWidget from './ImagelistWidget';
import HandleApplication from './HandleApplication';
import HandleBrochure from './HandleBrochure';
// ----------------------------------------------------------------------
const StyledCardImg = styled('img')({
  top: 0,
  width: '100%',
  height: '280px',
  objectFit: 'cover',
  position: 'absolute',
});
// ----------------------------------------------------------------------

FeatureWidget.propTypes = {
  infos: PropTypes.array,
};

function FeatureWidget({ infos }) {
  return (
    <>
      <Typography variant="subtitle2" sx={{ pl: 3 }} noWrap>
        Quick info
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns: { xs: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' },
        }}
      >
        {infos.map((site, index) => (
          <Paper key={index} variant="outlined" sx={{ py: 2.5, textAlign: 'center' }}>
            <Box sx={{ mb: 0.5 }}>
              <Iconify icon={site.tile.iconcode} color={site.tile.title} width={32} />
            </Box>
            <Stack>
              <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
                {site.tile.title}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {site.value}
              </Typography>
            </Stack>
          </Paper>
        ))}
      </Box>
    </>
  );
}

// ----------------------------------------------------------------------

FacilityWidget.propTypes = {
  facilities: PropTypes.array,
};

function FacilityWidget({ facilities }) {
  return (
    <>
      <Typography variant="subtitle2" sx={{ pl: 3 }} noWrap>
        Facilities
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns: { xs: 'repeat(2, 1fr)', md: 'repeat(5, 1fr)' },
          py: 2,
        }}
      >
        {facilities.map((site, index) => (
          <Box key={index} variant="outlined" sx={{ py: 2.5 }}>
            <Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>
              <Box component={'img'} src={site.iconcode} alt={site.title} sx={{ width: '50px', height: '50px' }} />
              <Typography variant="body2" sx={{ color: 'text.secondary', textTransform: 'capitalize' }}>
                {site.title}
              </Typography>
            </Stack>
          </Box>
        ))}
      </Box>
    </>
  );
}
// ----------------------------------------------------------------------

CollegeMainWidget.propTypes = {
  college: PropTypes.object,
};

export default function CollegeMainWidget({ college }) {
  const theme = useTheme();

  if (!college) {
    return <Widget404 item="Course" subitem={`No data found`} />;
  }

  const {
    slug,
    title,
    image,
    cover,
    intro,
    body,
    location,
    courses,
    facilities,
    college_info,
    college_media,
    brochure,
  } = college;
  return (
    <>
      <Grid item xs={12} sx={{ p: 2 }}>
        <Card>
          <Box sx={{ mb: { xs: '80%', sm: '50%', md: '35%' }, position: 'relative' }}>
            <StyledCardImg alt={title} src={cover} />
          </Box>
          <Stack spacing={2} direction={'row'}>
            <Box
              component={'img'}
              src={image}
              alt={title}
              sx={{ width: { xs: '80px', md: '150px' }, height: { xs: '80px', md: '150px' } }}
            />
            <Stack direction={'column'} sx={{ py: 2 }}>
              <Typography variant="h3" noWrap>
                {title}
              </Typography>

              <Typography component="span" variant="boby2" sx={{ mt: 2 }}>
                <Iconify icon={'eva:pin-fill'} color={'#000'} width={24} sx={{ mx: 0.9, pt: 0.9 }} />
                {location}
              </Typography>
            </Stack>
          </Stack>

          <Stack spacing={2} sx={{ p: 3 }}>
            <Typography variant="body1">{intro}</Typography>

            <FeatureWidget infos={college_info} />
            <Box
              sx={{
                display: 'grid',
                gap: 2,
                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  md: 'repeat(3, 1fr)',
                  lg: 'repeat(4, 1fr)',
                },
                p: 2,
                cursor: 'pointer',
              }}
            >
              <HandleApplication title={title} collegeslug={slug} />
              {brochure && <HandleBrochure url={brochure}  title={`${title}-brochure`}/>}
            </Box>
            <Divider />
            <Markdown children={body} />
          </Stack>
          <FacilityWidget facilities={facilities} />

          <ImageListWidget imagelist={college_media} />
        </Card>
      </Grid>
      <Typography variant="h5" sx={{ pl: 3, pt: 3 }} noWrap>
        Course List
      </Typography>
      <CourseListWidget courses={courses} />
    </>
  );
}
