import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Link, Card, Grid, Stack, Divider, Typography } from '@mui/material';
// components
import { Widget404 } from '../../components/widegts';
import Label from '../../components/label';
// utils
//
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

const StyledCollegeImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

CollegeCard.propTypes = {
  college: PropTypes.object,
};

function CollegeCard({ college }) {
  const { slug, title, image, intro, body, location } = college;
  const linkTo = `/college/${slug}`;
  return (
    <Grid item xs={12}>
      <Card sx={{ px: 2, py:1, margin: '2rem auto' }}>
        <Stack spacing={2} direction={'row'}>
          <Box component={'img'} src={image} alt={title} sx={{ width:{xs:'80px',md:'150px'}, height:{xs:'80px',md:'150px'} }} />
          <Stack direction={'column'} sx={{ py: 2 }}>
            <Link color="inherit" underline="hover" component={RouterLink} to={linkTo}>
              <Typography variant="h3" noWrap>
                {title}
              </Typography>
            </Link>
            <Typography variant="body2">{location}</Typography>
           
          </Stack>
          <Divider />
        </Stack>
        
        <Stack spacing={2} sx={{ p: 3 }}>
          <Typography variant="body1">{intro}</Typography>
          <Link color="inherit" underline="none" component={RouterLink} to={linkTo}>
            <Typography variant="body2" color={'#1939B7'}>View Detail</Typography>
          </Link>
        </Stack>
      </Card>
    </Grid>
  );
}

// ----------------------------------------------------------------------

CollegeListWidget.propTypes = {
  colleges: PropTypes.array,
};

export default function CollegeListWidget({ colleges }) {
  const theme = useTheme();
  if (!colleges) {
    return <></>;
  }

  return (
    <Grid container spacing={theme.spacing(0.5)} >
      {colleges && colleges.map((college, index) => <CollegeCard key={index} college={college} />)}
    </Grid>
  );
}
