import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect } from 'react';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Button,Link, Card, Grid,  Typography } from '@mui/material';
import {Widget404, LoadSkeleton} from '../../../components/widegts';

// redux
import { useDispatch, useSelector } from '../../../redux/store';
import { getServices } from '../../../redux/slices/course';

// ----------------------------------------------------------------------

const StyledTitle = styled(Link)({
  height: 64,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});

// ----------------------------------------------------------------------
ServiceCard.propTypes = {
  service: PropTypes.object.isRequired,
};

function ServiceCard({ service }) {
  const theme = useTheme();
  const { slug, title, intro } = service;
  const linkto = `/service/${slug}`;

  return (
    <Grid item xs={12} sm={6}>
      <Card sx={{ position: 'relative', px: theme.spacing(3), py: theme.spacing(6) }}>
        <StyledTitle
          color="inherit"
          underline="none"
          variant="h4"
          sx={{
            textAlign: 'center',
          }}
        >
          {title}
        </StyledTitle>

        <Typography variant="subtitle2" sx={{ py: theme.spacing(5), px: theme.spacing(5) }}>
          {' '}
          {intro.slice(0, 200)}
        </Typography>

        <Button to={linkto} size="small" variant="contained" component={RouterLink} sx={{ mx: theme.spacing(2) }}>
          Read more
        </Button>
      </Card>
    </Grid>
  );
}

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
MoreServiceWidget.propTypes = {
  currentservice: PropTypes.string,
};

export default function MoreServiceWidget({ currentservice }) {
  const dispatch = useDispatch();
  const { services, error, isLoading } = useSelector((state) => state.course);

  useEffect(() => {
    dispatch(getServices());
  }, [dispatch]);

  if (!services && isLoading) {
    return (
    <LoadSkeleton />
    );
  }
  if (!services && error) {
    return (
      <Widget404 item='Service' />    

    );
  }
  if (!services ) {
    return (
      <LoadSkeleton />
    );
  }


  const moreservices = services.filter((_service) => _service.slug !== currentservice) ;
  return (
    <Grid container spacing={3}>
      {moreservices.map((service, index) => (
        <ServiceCard key={index} service={service} />
      ))}
    </Grid>
  );
}