import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices

// import chatReducer from './slices/chat';
// import blogReducer from './slices/blog';
// import userReducer from './slices/user';
// import productReducer from './slices/product';
// import calendarReducer from './slices/calendar';
// import kanbanReducer from './slices/kanban';
// basic
import homeReducer from './slices/home';
import courseReducer from './slices/course';
import cmsReducer from './slices/cms';
import campusReducer from './slices/campus';
// import tagReducer from './slices/tag';
// import chapterReducer from './slices/chapter';
// import packReducer from './slices/pack';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

/* const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
}; */

const rootReducer = combineReducers({
  home: homeReducer,
  course: courseReducer,
  cms:cmsReducer,
  campus:campusReducer,
  // tag:tagReducer,
  // chapter:chapterReducer,
  // pack: packReducer,

  // mail: mailReducer,
  // chat: chatReducer,
  // blog: blogReducer,
  // user: userReducer,
  // calendar: calendarReducer,
  // kanban: kanbanReducer,
 // product: persistReducer(productPersistConfig, productReducer)
});

export { rootPersistConfig, rootReducer };
