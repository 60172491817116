import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Divider, Button, Stack, Container, Typography } from '@mui/material';
// components
import HeaderBreadcrumbs from '../../components/header-breadcrumbs';
import Logo from '../../components/logo';
import Iconify from '../../components/iconify';
// hooks
import useResponsive from '../../hooks/useResponsive';
// APP Name
import { AppName } from '../../config';
// sections
import { DeleteAccForm } from '../../sections/auth/delaccount';
// ----------------------------------------------------------------------
const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
 // boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function DeleteAccount() {
  const theme = useTheme();
  const mdUp = useResponsive('up', 'md');
  const pageTitle = 'Delete Account';


  return (
    <>
      <Helmet>
        <title>
          {pageTitle} | {AppName}
        </title>
      </Helmet>

      <StyledRoot>
        <HeaderBreadcrumbs
          heading={pageTitle}
          links={[
            {
              name: 'Home',
              href: '/',
            },
            { name: `${pageTitle}` },
          ]}
        />


        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              We feel sorry.
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Delete ASAPUAE User Account
            </Typography>

            <Typography variant="h6" gutterBottom>
              You can't login to user acccount after deleting Account, we will completely wipe your data from our system.
            </Typography>

            <DeleteAccForm />
          </StyledContent>
        </Container>
        </StyledRoot>
    </>
  );
}
