import PropTypes from 'prop-types';
// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
// components
import { Widget404 } from '../../components/widegts';
import Label from '../../components/label';
// utils
//
import Iconify from '../../components/iconify';

import CourseListWidget from './CourseListWidget';
import CollegeListWidget from './CollegeListWidget';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

SearchResultWidget.propTypes = {
  result: PropTypes.object,
  searchText: PropTypes.string,
};

export default function SearchResultWidget({ result, searchText }) {
  const theme = useTheme();

  const { courses, colleges } = result || {courses:[], colleges:[] };
  const hasNoData = colleges.Length ===0 && courses.Length ===0;

  if (hasNoData) {
    return <Widget404 item="Course" subitem={`No data found as ${searchText} `} />;
  }

  
  return (
    <>
    <CollegeListWidget colleges={colleges}/>
    <CourseListWidget courses={courses} />
    </>
  );
}
