// @mui
import { Box } from '@mui/material';
// utils
import { Gmap } from '../../../components/map';

// ----------------------------------------------------------------------

const mapdata =[
  {'country':'UAE',  'latlng':[25.798873, 55.976486]}, 
  {'country':'IND',  'latlng':[10.846982, 76.030389]}
]

// ----------------------------------------------------------------------


export default function ConatctMap({ ...other }) {
  
  return (
    <Box {...other}>
      <Gmap mapdata={mapdata} />
    </Box>
  );
}
