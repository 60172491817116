import { Link as RouterLink } from 'react-router-dom';
// mui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Grid, Link, Divider, Container, Typography, IconButton, Stack } from '@mui/material';
// routes
import Iconify from '../../../components/iconify';
//
import Logo from '../../../components/logo';

import { SOCIALS, LINKS, CONATCTLIST, LINK_DOWNLOADS} from '../fdata';
// ----------------------------------------------------------------------

const StyledAddress = styled(Typography)({
  pt: 1,
  height: 84,
  width: 200,
  overflow: 'hidden',
  WebkitLineClamp: 4,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  textAlign: 'left',
});

const StyledTitle = styled(Typography)({
  pt: 1,
  height: 34,
  width: 200,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  textAlign: 'left',
});
// ----------------------------------------------------------------------


const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.common.black,
  paddingBottom: theme.spacing(10),
  color: '#9999',
}));

// ----------------------------------------------------------------------

export default function Footer() {
  const theme = useTheme();
  const cyear = '2024';
  return (
    <RootStyle>
      <Divider sx={{ my: 5 }} />
      <Container maxWidth="lg">
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} md={3} sx={{ mb: 3 }}>
            <Logo url='/assets/logos/asap.png' sx={{ height: '60px', mx: { xs: 'auto', md: 'inherit' } }} />
            <Typography variant="body2" color="#999" sx={{ pr: { md: 5 } }}>
              DREAM | SEEK | ARCHIEVE
            </Typography>
            <Stack
              spacing={1.5}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 3, mb: { xs: 5, md: 0 } }}
            >
              {SOCIALS.map((social) => (
                <IconButton key={social.name} color="primary" sx={{ p: 1 }}>
                  <Iconify icon={social.icon} sx={{ width: 16, mr: 0.5 }} />
                </IconButton>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box color={'#fffe'}>
              {LINKS.map((list) => {
                const { headline, children } = list;
                return (
                  <Box key={headline} spacing={2} sx={{ display: 'grid' }}>
                    <Typography component="p" variant="overline">
                      {headline}
                    </Typography>
                    {children.map((link) => (
                      <Link
                        to={link.href}
                        key={link.name}
                        color="inherit"
                        variant="body2"
                        component={RouterLink}
                        sx={{ display: 'block', my: 0.7, textDecoration: 'none' }}
                      >
                        {link.name}
                      </Link>
                    ))}
                  </Box>
                );
              })}
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box color={'#fffe'}>
              {CONATCTLIST.map((site) => (
                <Box key={site.name} spacing={2} sx={{ display: 'grid' }}>
                  <StyledTitle component="p" variant="overline">
                    {site.name}
                  </StyledTitle>

                  <StyledTitle component="p" variant="overline">
                    +{site.mobile}
                  </StyledTitle>
                  <StyledTitle component="p" variant="overline" textTransform={'lowercase'}>
                    {site.email}
                  </StyledTitle>
                  <StyledAddress flexWrap variant="body2" sx={{ pr: 1 }}>
                    {site.address}
                  </StyledAddress>
                </Box>
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Stack spacing={2} direction={{ xs: 'row', md: 'column' }} alignItems="center">
              <Typography component="p" variant="overline" color="#999">
                {'Download App'}
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                spacing={{
                  xs: 0.5,
                  sm: 1,
                }}
                sx={{ mx: theme.spacing(5) }}
              >
                {LINK_DOWNLOADS.map((link) => (
                  <a target="blank" href={link.href} key={link.name} color="inherit">
                    <Box component="img" src={link.logo} sx={{ width: '200px', borderRadius: 1.5 }} />
                  </a>
                ))}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Grid item xs={12} alignItems="center">
        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 5,
            pb: 3,
            fontSize: 13,
            color: '#999',
            textAlign: { xs: 'center' },
          }}
        >
          © 2021-{cyear} Garliksofts. All rights reserved.
        </Typography>
      </Grid>
    </RootStyle>
  );
}
