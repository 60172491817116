import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { motion } from 'framer-motion';
import { useState, useRef } from 'react';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Card, Paper, Button, Typography, CardContent } from '@mui/material';
// utils
//
import { varFadeInRight, MotionContainer } from '../../components/animate';
import { CarouselControlsArrowsIndex } from '../../components/carousel/controls';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`,
  };
}

// ----------------------------------------------------------------------

const CarouselImgStyle = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'scale-down',
});

const CarouselVidStyle = styled('video')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

// ----------------------------------------------------------------------

ImageListWidget.propTypes = {
  imagelist: PropTypes.array,
};

export default function ImageListWidget({ imagelist }) {
  const theme = useTheme();
  const carouselRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(theme.direction === 'rtl' ? imagelist.length - 1 : 0);

  const settings = {
    speed: 500,
    dots: false,
    arrows: false,
    autoplay: true,
    slidesToShow: imagelist.length<4? imagelist.length:4,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === 'rtl'),
    beforeChange: (current, next) => setCurrentIndex(next),
  };

  const handlePrevious = () => {
    carouselRef.current.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current.slickNext();
  };

  return (
    <Card sx={{m:theme.spacing(3)}}>
      <Slider ref={carouselRef} {...settings}>
        {imagelist.map((item, index) => (
          <RenderItem key={index} item={item} isActive={index === currentIndex} />
        ))}
      </Slider>

      <CarouselControlsArrowsIndex
        index={currentIndex}
        total={imagelist.length}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />
    </Card>
  );
}

//   return (
//     <ImageList sx={{ width: 500, height: 450 }} variant="woven" cols={3} gap={8}>
//       {imagelist.map((item) => (
//         <ImageListItem key={item.file}>
//           <img
//             srcSet={`${item.file}?w=161&fit=crop&auto=format&dpr=2 2x`}
//             src={`${item.file}?w=161&fit=crop&auto=format`}
//             alt={item.title}
//             loading="lazy"
//           />
//         </ImageListItem>
//       ))}
//     </ImageList>
//   );
// }

// ----------------------------------------------------------------------

RenderItem.propTypes = {
  item: PropTypes.object,
  isActive: PropTypes.bool,
};

function RenderItem({ item, isActive }) {
  const { type, url, title } = item;
  const hasVideo = type === 'video';
  if (hasVideo) {
    return (
      <CarouselVidStyle  >
        <source src={url} type="video/mp4"/>
        <source src={url} type="video/ogg" />
        Your browser does not support the video tag.
        </CarouselVidStyle>
    );
  }
  return (
    <CarouselImgStyle alt={title} src={url} />
    
  );
}
