import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Container, Typography } from '@mui/material';

// APP Name
import { AppName } from '../../config';
// ----------------------------------------------------------------------
// components
import { ContactWidget, BenfitWidget } from '../../sections/web/home';
import { AboutWidget } from '../../sections/web/about';

// ----------------------------------------------------------------------

export default function AboutPage() {
  return (
    <>
      <Helmet>
        <title> About Us | {AppName} </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <AboutWidget />

          <Grid item xs={12}>
            <BenfitWidget />
          </Grid>

          <Grid item xs={12}>
            <ContactWidget />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
