import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import PropTypes from 'prop-types';
// import { useSnackbar } from 'notistack';

// @mui
import { Box, Card, Stack, Typography, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// utils
import { Api } from '../../../utils/api';

// ----------------------------------------------------------------------
async function createContact(values) {
  const { name, email, phone, comment, active } = values;
  const formData = new FormData();
  formData.append('name', `${name}`);
  formData.append('email', `${email}`);
  formData.append('phone', `${phone}`);
  formData.append('comment', `${comment}`);
  formData.append('active', active);
  const response = await Api.unpost('/api/basic/home', formData);
  return response;
}
// ----------------------------------------------------------------------
ConsultForm.propTypes = {
  title: PropTypes.string,
};
export default function ConsultForm({title}) {
  // const { enqueueSnackbar } = useSnackbar();
  const newvalidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.string().required('Phone is required'),
    email: Yup.string().required('Email is required'),
    comment: Yup.string().required('Message is required').min(10).max(5000),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      comment: '',
      active: true,
    },
    validationSchema: newvalidationSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        await createContact(values);

        resetForm();
        setSubmitting(false);
        // enqueueSnackbar('Message sent Successfully');
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors({ afterSubmit: error.code });
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Card sx={{ p: { xs: 2, md: 5 } }}>
      <Typography variant="h5" sx={{ my: 3 }}>
        {title}
      </Typography>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              label={'Name'}
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              fullWidth
              label={'Email'}
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <TextField
              fullWidth
              label={'Phone Number'}
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />
            <TextField
              fullWidth
              multiline
              maxRows={4}
              label={'Message'}
              {...getFieldProps('comment')}
              error={Boolean(touched.comment && errors.comment)}
              helperText={touched.comment && errors.comment}
            />
          </Stack>
          <Box sx={{ mb: 3 }} />

          <LoadingButton fullWidth size="large" type="submit" variant="contained">
            Submit
          </LoadingButton>
        </Form>
      </FormikProvider>
    </Card>
  );
}
