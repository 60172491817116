import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const COURSE_NAME = [
  'Biochemistry technologist',
  'GENERAL DENTISTRY',
  'General Practitioner',
  'Laboratory technologist',
  'MEDICAL CODING AND BILLING',
  'Medical Laboratory Technology Crash Course',
  'Microbiology Crash Course',
  'Microbiology technologist',
  'Molecular Biology Technologist',
  'NURSING',
  'Radiography Technologist'
];
const PRODUCT_COLOR = ['#00AB55', '#000000', '#FFFFFF', '#FFC0CB', '#FF4842', '#1890FF', '#94D82D', '#FFC107'];

// ----------------------------------------------------------------------

export const courselist = [...Array(11)].map((_, index) => {
  const setIndex = index + 1;

  return {
    id: faker.datatype.uuid(),
    img: `/assets/images/products/product_${setIndex}.jpg`,
    name: COURSE_NAME[index],
    slug: COURSE_NAME[index],
    intro: faker.lorem.paragraphs(),
    tag:{
      name:'DOCTOR',
      img: `/assets/images/avatars/avatar_${setIndex}.jpg`,
    },
    price: faker.datatype.number({ min: 4, max: 99, precision: 0.01 }),
    priceSale: setIndex % 3 ? null : faker.datatype.number({ min: 19, max: 29, precision: 0.01 }),
    colors:
      (setIndex === 1 && PRODUCT_COLOR.slice(0, 2)) ||
      (setIndex === 2 && PRODUCT_COLOR.slice(1, 3)) ||
      (setIndex === 3 && PRODUCT_COLOR.slice(2, 4)) ||
      (setIndex === 4 && PRODUCT_COLOR.slice(3, 6)) ||
      (setIndex === 23 && PRODUCT_COLOR.slice(4, 6)) ||
      (setIndex === 24 && PRODUCT_COLOR.slice(5, 6)) ||
      PRODUCT_COLOR,
    status: sample(['sale', 'new', '', '']),
  };
  
});

export const collegelist = [...Array(11)].map((_, index) => {
  const setIndex = index + 1;

  return {
    id: faker.datatype.uuid(),
    img: `/assets/images/products/product_${setIndex}.jpg`,
    name: COURSE_NAME[index],
    slug: COURSE_NAME[index],
    intro: faker.lorem.paragraphs(),
    tag:{
      name:'DOCTOR',
      img: `/assets/images/avatars/avatar_${setIndex}.jpg`,
    },
    price: faker.datatype.number({ min: 4, max: 99, precision: 0.01 }),
    priceSale: setIndex % 3 ? null : faker.datatype.number({ min: 19, max: 29, precision: 0.01 }),
    status: sample(['sale', 'new', '', '']),
  };
  
});

export const streams = [...Array(5)].map((_, index) => {
  const setIndex = index + 1;

  return {
    id: faker.datatype.uuid(),
    img: `/assets/images/products/product_${setIndex}.jpg`,
    name: COURSE_NAME[index],
    slug: COURSE_NAME[index],
    intro: faker.lorem.paragraphs(),
    tag:{
      name:'DOCTOR',
      img: `/assets/images/avatars/avatar_${setIndex}.jpg`,
    },
    price: faker.datatype.number({ min: 4, max: 99, precision: 0.01 }),
    priceSale: setIndex % 3 ? null : faker.datatype.number({ min: 19, max: 29, precision: 0.01 }),
    status: sample(['sale', 'new', '', '']),
  };
  
});

export const searchres ={
  courses :courselist,
  colleges:collegelist,

}

export const college ={
  id: faker.datatype.uuid(),
  img: `/assets/images/products/product_${3}.jpg`,
  name: COURSE_NAME[3],
  slug: COURSE_NAME[3],
  intro: faker.lorem.paragraphs(),
  tag:{
    name:'DOCTOR',
    img: `/assets/images/avatars/avatar_${3}.jpg`,
  },
  price: faker.datatype.number({ min: 4, max: 99, precision: 0.01 }),
  priceSale: 3 % 3 ? null : faker.datatype.number({ min: 19, max: 29, precision: 0.01 }),
  status: sample(['sale', 'new', '', '']),
  courses :courselist,
}

export const course ={
  
    id: faker.datatype.uuid(),
    img: `/assets/images/products/product_${2}.jpg`,
    name: COURSE_NAME[2],
    slug: COURSE_NAME[2],
    intro: faker.lorem.paragraphs(),
    tag:{
      name:'DOCTOR',
      img: `/assets/images/avatars/avatar_${2}.jpg`,
    },
    price: faker.datatype.number({ min: 4, max: 99, precision: 0.01 }),
    priceSale: 2 % 3 ? null : faker.datatype.number({ min: 19, max: 29, precision: 0.01 }),
    status: sample(['sale', 'new', '', '']),
    colleges:collegelist,

}