import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import CampusLayout from './layouts/campus';
import SimpleLayout from './layouts/simple';
//
import Page404 from './pages/Page404';
// CMS
import PrivacyPage from './pages/cms/PrivacyPage';
import TermsPage from './pages/cms/TermsPage';
import RefundPage from './pages/cms/RefundPage';
import DeletePage from './pages/cms/DeletePage';
//
import HomePage from './pages/web/HomePage';
import AboutPage from './pages/web/AboutPage';
import TeamsPage from './pages/web/TeamsPage';
import ContactPage from './pages/web/ContactPage';
import ServicePage from './pages/web/ServicePage';
import CoursesPage from './pages/web/CoursesPage';
import CourseDetailpage from './pages/web/CourseDetailpage';
import ServiceDetailpage from './pages/web/ServiceDetailpage';

// campus
import SearchPage from './pages/campus/SearchPage';
import CollegePage from './pages/campus/CollegePage';
import CoursePage from './pages/campus/CoursePage';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([  
    
    {
      path: '/',
      element: <SimpleLayout />,
      children: [
        { path: '', element: <HomePage /> },
        { path: 'about-us', element: <AboutPage /> },
        { path: 'contact-us', element: <ContactPage /> },
        { path: 'services', element: <ServicePage /> },
        { path: 'courses', element: <CoursesPage /> },
        { path: 'team', element: <TeamsPage /> },
        { path: 'course/:courseslug', element:  <CourseDetailpage/>},
        { path: 'service/:serviceslug', element:  <ServiceDetailpage/>},
        { path: 'privacy/', element:  <PrivacyPage/>},
        { path: 'terms/', element:  <TermsPage/>},
        { path: 'refund/', element:  <RefundPage/>},
        { path: 'delete-account/', element:  <DeletePage/>},
        { path: '*', element: <Page404 /> },
      ],
    },
    {
      path: '/',
      element: <CampusLayout />,
      children: [
        { path: 'campus', element:  <SearchPage/>},
        { path: 'college/:cmpcollegeslug', element:  <CollegePage/>},
        { path: 'cmpcourse/:cmpcourseslug', element:  <CoursePage/>},
   
      ],
    },
    {
      element: <SimpleLayout />,
      children: [       
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
