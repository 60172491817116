import PropTypes from 'prop-types';

// material
import {  alpha,  useTheme, styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import {MIconButton} from '../../@mui-extend';
//
import Iconify from '../../iconify';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  zIndex: 9,
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  color: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.grey[900], 0.48)
}));

const ArrowStyle = styled(MIconButton)(({ theme }) => ({
  padding: 6,
  opacity: 0.48,
  color: theme.palette.common.white,
  '&:hover': { opacity: 1 }
}));

// ----------------------------------------------------------------------

CarouselControlsArrowsIndex.propTypes = {
  index: PropTypes.number,
  total: PropTypes.number,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func
};

export default function CarouselControlsArrowsIndex({
  index,
  total,
  onNext,
  onPrevious,
  ...other
}) {
  const theme = useTheme();
  const isRTL = theme.direction === 'rtl';

  return (
    <RootStyle {...other}>
      <ArrowStyle size="small" onClick={onPrevious}>
      <Iconify icon={isRTL ? 'eva:arrow-right-fill': 'eva:arrow-left-fill'} sx={{ width: 20, height: 20, mr: 0.5 }} />
      
      
      </ArrowStyle>

      <Typography variant="subtitle2">
        {index + 1}/{total}
      </Typography>

      <ArrowStyle size="small" onClick={onNext}>
      <Iconify icon={isRTL ? 'eva:arrow-left-fill':'eva:arrow-right-fill'} sx={{ width: 20, height: 20, mr: 0.5 }} />
       
      </ArrowStyle>
    </RootStyle>
  );
}
