// import { Link as ScrollLink } from 'react-scroll';
import { useLocation, Outlet } from 'react-router-dom';

// material
import { styled } from '@mui/material/styles';

// components
// import Logo from '../../components/logo';
// import Header from './header';
import MainNavbar from './nav';
import MainFooter from './footer';

import WhatsappFLoating from '../shared/WhatsappFLoating';
// ----------------------------------------------------------------------
const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(15),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
  
  },
}));
// ----------------------------------------------------------------------

export default function SimpleLayout() {
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  return (
    <>
      <MainNavbar />
      <MainStyle>
        <Outlet />
      </MainStyle>

      {!isHome ? <MainFooter /> : <MainFooter />}
      <WhatsappFLoating />
    </>
  );
}
