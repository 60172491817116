import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography, Stack } from '@mui/material';

// utils
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

/*

*/
const BoxDesign = styled(Box)({
  position: 'fixed',
  bottom: '5px',
  right: '4px',
  borderRadius: '15px',
  cursor: 'pointer',
  // backgroundColor: '#25D366',
  // '&:hover': {
  //   backgroundColor: '#128C7E',
  // },
});
// ----------------------------------------------------------------------

export default function WhatsappFLoating() {
  const handleClick = () => {
    window.location.replace('https://api.whatsapp.com/send?phone=971526353211');
  };

  return (
    <BoxDesign>
      <Stack
        // spacing={0.75}
        direction="row"
        justifyContent="space-around"
        // marginX={1}
        // sx={{ py: 0.5 }}
        onClick={handleClick}
      >
        <Iconify
          icon={'ic:baseline-whatsapp'}
          color={'#25D366'}
          sx={{
            '&:hover': {
              color: '#128C7E',
            },
          }}
          width={48}
        />
      </Stack>
    </BoxDesign>
  );
}
