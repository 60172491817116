import axios from 'axios';
import {tokenBeaer} from '../../config';
import {LOGIN_TOKEN_REFRESH_URL} from './urls';

const isLive=true;
const liveURL = 'https://api.asapuae.com';
const testURL = 'https://api.asapuae.com';


function getURL(){
    return isLive? liveURL:testURL
}

const axiosInstance = axios.create({
  baseURL:getURL()
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (typeof error.response === 'undefined') {
      /* alert(
				'A server/network error occurred. ' +
					'Looks like CORS might be the problem. ' +
					'Sorry about this - we will get it fixed shortly.'
			); */

      console.log(
        'A server/network error occurred. ' +
          'Looks like CORS might be the problem. ' +
          'Sorry about this - we will get it fixed shortly.'
      );
      return Promise.reject(error);
    }

    if (error.response.status === 401 && originalRequest.url === LOGIN_TOKEN_REFRESH_URL) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      window.location.href = '/auth/login/';
      return Promise.reject(error);
    }

    if (
      error.response.data.code === 'token_not_valid' &&
      error.response.status === 401 &&
      error.response.statusText === 'Unauthorized'
    ) {
      const refreshToken = localStorage.getItem('refresh_token');

      if (refreshToken) {
        const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
          return axiosInstance
            .post('/api/token/refresh/', { refresh: refreshToken })
            .then((response) => {
              localStorage.setItem('access_token', response.data.access);
              localStorage.setItem('refresh_token', response.data.refresh);
              // use if requied //changes
              axiosInstance.defaults.headers.Authorization = `${tokenBeaer} ${response.data.access}`;
              originalRequest.headers.Authorization = `${tokenBeaer} ${  response.data.access}`;

              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              console.log(err);
            });
        } 
          console.log('Refresh token is expired', tokenParts.exp, now);
          window.location.href = '/auth/login/';
        
      } else {
        console.log('Refresh token not available.');
        window.location.href = '/auth/login/';
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default axiosInstance;
