import { createSlice } from '@reduxjs/toolkit';
// utils
import {Api} from '../../utils/api';
import {CMS_URLS} from '../../utils/api/urls';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  hasMore: false,
  cms: '',
  index: 0,
  step: 11,
};

const slice = createSlice({
  name: 'cms',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // HAS MORE
    noHasMore(state) {
      state.hasMore = false;
    },

    // GET CMS
    getCmsSuccess(state, action) {
      state.isLoading = false;
      state.cms = action.payload;
    },


  }
});

// Reducer
export default slice.reducer;


// ----------------------------------------------------------------------

export function getCms(type) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Api.unget(`${CMS_URLS.cms}${type}/`);
      dispatch(slice.actions.getCmsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
