import PropTypes from 'prop-types';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, AppBar, Toolbar, Container, Stack, Typography } from '@mui/material';

// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
// components
import Iconify from '../../../components/iconify';
import Logo from '../../../components/logo';
import { MHidden } from '../../../components/@mui-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from '../../simple/header/config';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP,
  },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <MHidden width="mdDown">
        <NavHeaderInfo isOffset={isOffset} />
      </MHidden>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 },
          }),
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <RouterLink to="/">
            <Logo url={'/assets/logos/asap-campus.png'} sx={{mx:2, height:'60px'}} />
          </RouterLink>

          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>

          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}

NavHeaderInfo.propTypes = {
  isOffset: PropTypes.bool,
};
function NavHeaderInfo({ isOffset }) {
  return (
    <Stack
      spacing={0}
      direction="row"
      justifyContent="space-around"
      sx={{
        color: 'common.white',
        ...(isOffset && {
          bgcolor: '#0288D1',
          height: { md: 16 },
        }),
      }}
    >
      <Box sx={{ flexGrow: 1 }} />
      <Stack spacing={0.5} direction="row" justifyContent="space-around" marginX={3}>
        <Iconify icon={'ic:round-call'} width={16} />
        <Typography variant="caption">+917558808704, 00971543494801</Typography>
      </Stack>
      <Stack spacing={0.5} direction="row" justifyContent="space-around" marginX={3}>
        <Iconify icon={'ic:round-mail-outline'} width={16} />
        <Typography variant="caption">info@asapuae.com, asapuae123@gmail.com</Typography>
      </Stack>
    </Stack>
  );
}
