import PropTypes from 'prop-types';
import {  useEffect } from 'react';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Card, Grid,  Typography } from '@mui/material';
// components

// utils
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import { getTeams } from '../../../redux/slices/course';

// ----------------------------------------------------------------------


const ContentItemStyle = styled('div')(({ theme }) => ({
  bottom: 0,
  zIndex: 9,
  width: '100%',
  display: 'block',
  position: 'absolute',
  alignItems: 'center',
  padding: theme.spacing(3),
  borderBottomLeftRadius: 16,
  backdropFilter: 'blur(8px)',
  WebkitBackdropFilter: 'blur(8px)', // Fix on Mobile
  borderBottomRightRadius: 16,
  justifyContent: 'space-between',
  backgroundColor: alpha(theme.palette.grey[900], 0.72),
  flexDirection: theme.direction === 'rtl' ? 'row-reverse' : 'row',
}));
//

// {icon: 'eva:share-fill',url:'#'},
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
TeamCard.propTypes = {
  team: PropTypes.object,
  index: PropTypes.number,
};
// ----------------------------------------------------------------------
function TeamCard({ team, index }) {
  const { name,role,avatar } = team;
  const latestItemLarge = index === 0;

  return (
    <Grid item xs={12} sm={latestItemLarge ? 12 : 6} md={latestItemLarge ? 6 : 3}>
      <Card sx={{ position: 'relative' }}>
        <Box sx={{ position: 'relative', zIndex: 0 }}>
          <Box component="img" alt={name} src={avatar} sx={{ width: '100%', height: {xs:480,md:400}, objectFit: 'cover' }} />

          <ContentItemStyle>
            <Typography variant="subtitle1" sx={{ color: 'common.white' }}>
              {name}
            </Typography>
            <Typography variant="caption" sx={{ color: 'common.white', textTransform: 'uppercase', }}>
              {role}
            </Typography>
          </ContentItemStyle>
        </Box>

      </Card>
    </Grid>
  );
}

// ----------------------------------------------------------------------
TeamWidget.propTypes = {
  isSlice: PropTypes.bool,
};

export default function TeamWidget({isSlice}) {
  const dispatch = useDispatch();
  const { teams, error, isLoading } = useSelector((state) => state.course);

  useEffect(() => {
    dispatch(getTeams());
  }, [dispatch]);

  if (!teams && isLoading) {
    return (
      <Typography variant="subtitle1" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
        Loading...
      </Typography>
    );
  }

  return (
    <Grid container spacing={3}>
      {isSlice ?
      teams.slice(0,6).map((team, index) => (
        <TeamCard key={index} team={team} />
      )) :
      teams.map((team, index) => (
        <TeamCard key={index} team={team} />
      ))}
    </Grid>
  );
}
