import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { Api } from '../../../utils/api';


// ----------------------------------------------------------------------
async function removeContact(values) {
  const { email, password, verifydata } = values;
  const formData = new FormData();
  formData.append('username', `${email}`);
  formData.append('password', `${password}`);
  formData.append('verifydata', `${verifydata}`);
  const response = await Api.unpost('/api/s/auth/account/del/', formData);
  return response;
}
// ----------------------------------------------------------------------

export default function DeleteAccForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => {
    navigate('/dashboard', { replace: true });
  };
  const newvalidationSchema = Yup.object().shape({
    email: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
    verifydata: Yup.string().equals('DELETE', "Type DELETE").required('Verify Test is required')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      verifydata: '',
    },
    validationSchema: newvalidationSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
       const  response = await removeContact(values);

        resetForm();
        setSubmitting(false);
        // enqueueSnackbar('Message sent Successfully');
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors({ afterSubmit: error.code });
      }
    },
  });

  const {errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Username" />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

      <TextField name="verifydata" label="Type 'DELELE' to conform" />
      </Stack>

     

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}  sx={{my:5}}>
        Submit
      </LoadingButton>
    </>
  );
}
