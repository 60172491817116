import PropTypes from 'prop-types';
// import { useSnackbar } from 'notistack';

// @mui
import { Box, Paper, Stack, Typography, Dialog } from '@mui/material';
//
import Iconify from '../../components/iconify';

// widgets

// ----------------------------------------------------------------------
HandleBrochure.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
};
export default function HandleBrochure({ url, title }) {
  const handleDownload = () => {
    // onClick={handleDownload}
  };
  return (
    <Paper
      variant="outlined"
      sx={{
        backgroundColor: '#71E9EE',
        mx: 4,
        px: 3,
        py: 1.5,
        '&:hover': {
          backgroundColor: '#091A7A',
        },
      }}
    >
      <Box component={'a'} href={url} download={title??url}>
      <Stack
        direction={'row'}        
        spacing={3}
        sx={{ textAlign: 'center', justifyContent: 'space-between', color: '#000', '&:hover': { color: '#fff' } }}
        
      >
        <Typography variant="body1">Brochure</Typography>
        <Iconify icon={'fluent:cloud-download-24-filled'} width={24} />
      </Stack>
      </Box>
    </Paper>
  );
}
