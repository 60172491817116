import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
// @mui
import { Grid, Container, Typography } from '@mui/material';

// APP Name
import { AppName } from '../../config';
// ----------------------------------------------------------------------
// components
import {Widget404, LoadSkeleton} from '../../components/widegts';
import { CollegeMainWidget } from '../../sections/@campus';

// redux
import { useDispatch, useSelector } from '../../redux/store';

// data
import { getCollege } from '../../redux/slices/campus';

// ----------------------------------------------------------------------

export default function CollegePage() {
    const { cmpcollegeslug } = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  

  const { college, error, isLoading } = useSelector((state) => state.campus);

  useEffect(() => {
    dispatch(getCollege(cmpcollegeslug));
  }, [dispatch, cmpcollegeslug]);

  if (!college && isLoading) {
    return <LoadSkeleton />;
  }
  if (!college && error) {
    return <Widget404 item="College" subitem={`No data found as ${cmpcollegeslug}`} />;
  }
  if (!college) {
    return <LoadSkeleton />;
  }
  return (
    <>
      <Helmet>
        <title> College Search | {AppName} </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
        <CollegeMainWidget college={college} />
          
        </Grid>
      </Container>
    </>
  );
}
