import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Stack, Grid, Typography } from '@mui/material';

// utils
import Iconify from '../../../components/iconify';
// ----------------------------------------------------------------------
const BoxDesign = styled('div')(({ theme }) => ({
  top: theme.spacing(3),
  left: theme.spacing(-3),
  backgroundColor: '#DF3E30',
  position: 'absolute',
  width: theme.spacing(3),
  height: '86%',
  borderStartStartRadius: '15px',
  borderEndStartRadius: '15px',
  '&:hover': {
    backgroundColor: '#1877F2',
  },
}));

// ----------------------------------------------------------------------
const LISTIMG = [
  { id: 1, url: 'assets/images/asap/about_01.jpg' },
  { id: 2, url: 'assets/images/asap/about_02.jpg' },
];

const LISTFEATURES = [
  { id: 1, body: 'We serve Programs like MOH, DHA, HAAD, OET and other training' },
  { id: 2, body: 'Online and Offline Classes.' },
  { id: 3, body: 'Well Experienced Trainers.' },
];

const LINK_DOWNLOADS = [
  {
    name: 'Android',
    logo: '/assets/images/store/playstore.png',
    href: 'https://play.google.com/store/apps/details?id=com.asapuae.garliksofts',
  },
  { name: 'IOS', logo: '/assets/images/store/appstore.png', href: 'https://apps.apple.com/app/id1668307429' },
];

// ph:circle-wavy-check-bold
// ----------------------------------------------------------------------

export default function AboutWidget() {
  const theme = useTheme();
  return (
    <Grid container spacing={3} sx={{ my: 3 }}>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            display: 'grid',
            gap: 0.8,
            mx: {xs:3,md:10},
            gridTemplateColumns: 'repeat(2, 1fr)',
            position: 'relative',
          }}
        >
          <BoxDesign />
          {LISTIMG.map((listImg) => (
            <Box key={listImg.id} sx={{ height: '556px', position: 'relative', borderRadius: '15px' }}>
              <Box
                component="img"
                src={listImg.url}
                sx={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  cursor: 'pointer',
                  objectFit: 'cover',
                  borderRadius: '15px',
                  '&:hover': { transform: 'scale(1.25)', transitionDuration: '3s' },
                }}
              />
            </Box>
          ))}
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="subtitle2" sx={{ color: '#DF3E30', display: 'block', textAlign: 'left' }}>
          About us
        </Typography>
        <Typography variant="h2" component={'h1'} sx={{ color: '#000', textAlign: 'left', my: 4 }}>
          Welcome to ASAP marketing and studies consultancy.
        </Typography>
        <Typography variant="body1" sx={{ color: '#000', display: 'block', textAlign: 'left', textJustify: 'auto' }}>
          ASAP Marketing and Studies Consultancy is an educational institute that specializes in providing prometric
          exams. Our team of experienced professionals is dedicated to helping individuals prepare for and pass their
          prometric exams with confidence. We offer a range of study materials, practice tests, and personalized
          coaching to ensure that our students are fully prepared for their exams.
        </Typography>
        <Box sx={{px:2,my:4}}>
          {LISTFEATURES.map((lst, ) => (
            <Stack key={lst.id} spacing={1.5} direction="row" justifyContent="flex-start" mb={1.5}>
              <Iconify icon={'lucide:check-circle'} color={'#DF3E30'} width={24} />

              <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                {lst.body}
              </Typography>
            </Stack>
          ))}
        </Box>
        <Stack
                direction="row"
                alignItems="center"
                spacing={{
                  xs: 0.5,
                  sm: 1,
                }}
                sx={{ mx: theme.spacing(5) }}
              >
                {LINK_DOWNLOADS.map((link) => (
                  <a
                  target="blank"
                   href={link.href}
                    key={link.name}
                    color="inherit"
                  >
                    <Box component="img" src={link.logo} sx={{ width: '200px', borderRadius: 1.5 }} />
                  </a>
                ))}
              </Stack>
      </Grid>
    </Grid>
  );
}
