import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';


import { useTheme, styled } from '@mui/material/styles';
import Iconify from '../iconify';

const StyledRoot = styled('div')({
    
    height: '70vh',
    width: '100%'
  });

  MarkerWidget.propTypes = {
    name: PropTypes.string,
  };

  function MarkerWidget({name}) {

    return  <Iconify icon={'material-symbols:location-on'} color="#DF3E30" width={48} height={48}  />;
  }

  Gmap.propTypes = {
    mapdata: PropTypes.array,
  };

  export default function Gmap({mapdata}) {
    const theme = useTheme();
    const zoom =15;
    const  center ={
        'lat': 25.798873,
        'lng':55.976486
      };

    const handleApiLoaded = (map, maps) => {
        // use map and maps objects
      };

  
    return (
        <StyledRoot>
        <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCXFj0R51PJgrNcDWXGvfs0tZB0pSRDFLE" }}
        defaultCenter={center}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
         {mapdata.map((marker) => (
        <MarkerWidget key={marker.country}
          lat={marker.latlng[0]}
          lng={marker.latlng[1]}
          text={marker.country}
        />
        ))}
      </GoogleMapReact>
        </StyledRoot>
    );
  }