import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import {  useEffect } from 'react';

// @mui
import {  useTheme } from '@mui/material/styles';
import { Box, Card, Grid, Container, Typography } from '@mui/material';
// components
import { FeatureWidget, ContactWidget } from '../../sections/web/home';

import { ServiceHero, MoreServiceWidget } from '../../sections/web/service';
import HeaderBreadcrumbs from '../../components/header-breadcrumbs';
import {Widget404, LoadSkeleton} from '../../components/widegts';
import Markdown from '../../components/markdown';

// APP Name
import { AppName } from '../../config';

// redux
import { useDispatch, useSelector } from '../../redux/store';

// data
import { getService } from '../../redux/slices/course';

// ----------------------------------------------------------------------

export default function ServiceDetailpage() {
  const { serviceslug } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { service, error, isLoading } = useSelector((state) => state.course);

  useEffect(() => {
    dispatch(getService(serviceslug));
  }, [dispatch, serviceslug]);

  if (!service && isLoading) {
    return (
    <LoadSkeleton />
    );
  }
  if (!service && error) {
    return (
      <Widget404 item='Service' subitem={`No data found as ${serviceslug}`}/>    

    );
  }
  if (!service ) {
    return (
      <LoadSkeleton />
    );
  }
  
  const pageTitle=  service ? service.title :serviceslug;

  return (
    <>
      <Helmet>
        <title>
         {pageTitle} | {AppName}
        </title>
      </Helmet>

      <Container maxWidth="xl">
      <HeaderBreadcrumbs
          heading={pageTitle}
          links={[
            {
              name: 'Services',
              href: '/services',
            },
            { name: `${pageTitle}` }
          ]}
        />
        <Card sx={{my:theme.spacing(3)}}>
         <ServiceHero service={service}/>
         <Box sx={{ p: { xs: 3, md: 5 } }}>
              <Typography variant="h6" sx={{ mb: 5 }}>
                {service.intro}
              </Typography>

              <Markdown children={service.body} />
              </Box>
         </Card>
        <Grid container spacing={3}>
       

          <Grid item xs={12}>
            <FeatureWidget
              title="Main Feature"
              subheader="Features we provide"              
            />
          </Grid>
          <Grid item xs={12}>
          <Typography variant="h3" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
              More Services
            </Typography>
           
          <MoreServiceWidget currentservice={serviceslug} />
          </Grid>

          <Grid item xs={12}>
            <ContactWidget />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
