import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Card, Grid, Container, Typography, Stack } from '@mui/material';
// components
import { FeatureWidget, ContactWidget, PassoutWidget } from '../../sections/web/home';

import { CourseHero, MoreCourseWidget } from '../../sections/web/course';
import HeaderBreadcrumbs from '../../components/header-breadcrumbs';
import { Widget404, LoadSkeleton } from '../../components/widegts';
import Markdown from '../../components/markdown';

// APP Name
import { AppName } from '../../config';

// redux
import { useDispatch, useSelector } from '../../redux/store';

// data
import { getCourse } from '../../redux/slices/course';

// ----------------------------------------------------------------------
const LINK_DOWNLOADS = [
  {
    name: 'Android',
    logo: '/assets/images/store/playstore.png',
    href: 'https://play.google.com/store/apps/details?id=com.asapuae.garliksofts',
  },
  { name: 'IOS', logo: '/assets/images/store/appstore.png', href: 'https://apps.apple.com/app/id1668307429' },
];

export default function CourseDetailPage() {
  const { courseslug } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { course, error, isLoading } = useSelector((state) => state.course);

  useEffect(() => {
    dispatch(getCourse(courseslug));
  }, [dispatch, courseslug]);

  if (!course && isLoading) {
    return <LoadSkeleton />;
  }
  if (!course && error) {
    return <Widget404 item="Course" subitem={`No data found as ${courseslug} `} />;
  }
  if (!course) {
    return <LoadSkeleton />;
  }

  const pageTitle = course ? course.name : courseslug;

  return (
    <>
      <Helmet>
        <title>
          {pageTitle} | {AppName}
        </title>
      </Helmet>

      <Container maxWidth="xl">
        <HeaderBreadcrumbs
          heading={pageTitle}
          links={[
            // {
            //   name: 'Courses',
            //   href: '/courses',
            // },
            { name: `${pageTitle}` },
          ]}
        />
        <Card sx={{ my: theme.spacing(3) }}>
          <CourseHero course={course} />
        </Card>
        <Grid container spacing={3}>
          <Grid item xs={12} >
            <Box sx={{ p: { xs: 3, md: 5 } }}>
              <Typography variant="h6" sx={{ mb: 5 }}>
                {course.introduction}
              </Typography>

              <Markdown children={course.about} />
            </Box>
{/* 
            <CourseCariculam subjects={course.subjects} /> */}
          </Grid>
          <Grid item xs={12}>
          <Stack
                direction="row"
                alignItems="center"
                spacing={{
                  xs: 0.5,
                  sm: 1,
                }}
                sx={{ mx: theme.spacing(5) }}
              >
                {LINK_DOWNLOADS.map((link) => (

                  <a
                  target="blank"
                  href={link.href}
                    key={link.name}
                    color="inherit"
                  >
                    <Box  component="img" src={link.logo} sx={{ width: '200px',  objectFit: 'fill', borderRadius: 1.5 }} />
                  </a>
                ))}
              </Stack>
          </Grid>
          
          {/* <Grid item xs={12} md={4}>
            <CourseDetailCard deatillist={course.details} />
          </Grid> */}

          <Grid item xs={12}>
            <FeatureWidget title="Main Feature" subheader="Features we provide" />
          </Grid>
          <PassoutWidget  course={courseslug}/>
          <Grid item xs={12}>
            <Typography variant="h3" sx={{ color: '#000', display: 'block', textAlign: 'center', mb:4 }}>
              More Courses
            </Typography>

            <MoreCourseWidget currentcourse={courseslug} />
          </Grid>

          <Grid item xs={12}>
            <ContactWidget />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
