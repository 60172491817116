import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
// @mui
import { Grid, Container, Typography } from '@mui/material';

// APP Name
import { AppName } from '../../config';
// ----------------------------------------------------------------------
// components
import { Widget404, LoadSkeleton } from '../../components/widegts';
import { CourseMainWidget } from '../../sections/@campus';

// redux
import { useDispatch, useSelector } from '../../redux/store';

// data
import { getCourse } from '../../redux/slices/campus';

// ----------------------------------------------------------------------

export default function CoursePage() {
  const { cmpcourseslug } = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);

  const { course, error, isLoading } = useSelector((state) => state.campus);

  useEffect(() => {
    dispatch(getCourse(cmpcourseslug));
  }, [dispatch, cmpcourseslug]);

  if (!course && isLoading) {
    return <LoadSkeleton />;
  }
  if (!course && error) {
    return <Widget404 item="Course" subitem={`No data found as ${cmpcourseslug}`} />;
  }
  if (!course) {
    return <LoadSkeleton />;
  }
  return (
    <>
      <Helmet>
        <title> Course Search | {AppName} </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <CourseMainWidget course={course} />
        </Grid>
      </Container>
    </>
  );
}
