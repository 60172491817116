// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
//
// import NotistackProvider from './components/notistack-provider';
// hooks
// import useAuth from './hooks/useAuth'
// components
import LoadingScreen from './components/loading-screen';
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';

// ----------------------------------------------------------------------

export default function App() {
  // const { isInitialized } = useAuth();
  const isInitialized = true; //
  return (
    <ThemeProvider>
      {/* <NotistackProvider> */}
        <ScrollToTop />
        <StyledChart />
        {isInitialized ? <Router /> : <LoadingScreen />}
      {/* </NotistackProvider> */}
    </ThemeProvider>
  );
}
