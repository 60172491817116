import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Paper, Link, Card, Grid, Stack, Divider, Typography } from '@mui/material';
// components
import { Widget404 } from '../../components/widegts';
import Label from '../../components/label';
// utils
//
import Iconify from '../../components/iconify';

import HandleApplication from './HandleApplication';
import HandleBrochure from './HandleBrochure';
// ----------------------------------------------------------------------

const StyledCourseImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

FeatureWidget.propTypes = {
  course: PropTypes.object,
};

function FeatureWidget({ course }) {
  const { graduation, modes, stream, duration } = course;
  const infos = [
    { title: 'Duration', icon: 'mdi:timer-sand', value: duration },
    { title: 'Mode', icon: 'mdi:clock', value: modes },
    { title: 'Graduation', icon: 'fluent:hat-graduation-16-regular', value: graduation.title },
    { title: 'Stream', icon: 'material-symbols:stream', value: stream.title },
  ];

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns: { xs: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)', lg: 'repeat(5, 1fr)' },
          py: 2,
        }}
      >
        {infos.map((site, index) => (
          <Paper key={index} variant="outlined" sx={{ py: 0.5 }}>
            <Stack direction={'row'} sx={{ px: 1, textAlign: 'center' }}>
              <Iconify icon={site.icon} color={site.title} width={32} />
              <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1, px: 1 }}>
                {site.value}
              </Typography>
            </Stack>
          </Paper>
        ))}
      </Box>
    </>
  );
}
// ----------------------------------------------------------------------

CourseCard.propTypes = {
  course: PropTypes.object,
};

function CourseCard({ course }) {
  const { slug, title, image, intro, body, stream } = course;
  const linkTo = `/cmpcourse/${slug}`;
  const hasBrochure = false;
  return (
    <Grid item xs={12}>
      <Card sx={{ px: 2, py: 1, margin: '2rem auto' }}>
        <Stack spacing={2} direction={'row'}>
          <Box
            component={'img'}
            src={image}
            alt={title}
            sx={{ width: { xs: '80px', md: '150px' }, height: { xs: '80px', md: '150px' } }}
          />
          <Stack direction={'column'} sx={{ py: 2 }}>
            <Link color="inherit" underline="hover" component={RouterLink} to={linkTo}>
              <Typography variant="h3" noWrap>
                {title}
              </Typography>
            </Link>
            <Typography variant="body2">{stream.title}</Typography>
          </Stack>
          <Divider />
        </Stack>
        <Stack spacing={2} sx={{ p: 3 }}>
          <Typography variant="body1">{intro}</Typography>
          <Link color="inherit" underline="none" component={RouterLink} to={linkTo}>
            <Typography variant="body2" color={'#1939B7'}>
              View Detail
            </Typography>
          </Link>
        </Stack>
        <FeatureWidget course={course} />
        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
              lg: 'repeat(4, 1fr)',
            },
            p: 2,
            cursor: 'pointer',
          }}
        >
          <HandleApplication title={title} courseslug={slug} />
          {hasBrochure &&  <HandleBrochure url={''} title={`${title}-brochure`} /> }
        </Box>
      </Card>
    </Grid>
  );
}

// ----------------------------------------------------------------------

CourseListWidget.propTypes = {
  courses: PropTypes.array,
};

export default function CourseListWidget({ courses }) {
  const theme = useTheme();
  if (!courses) {
    return <></>;
  }
  return (
    <Grid container spacing={theme.spacing(0.5)} sx={{ pb: 0.5 }}>
      {courses && courses.map((course) => <CourseCard key={course.slug} course={course} />)}
    </Grid>
  );
}
// ----------------------------------------------------------------------
