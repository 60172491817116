import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';

// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Card, Grid, Container, Typography } from '@mui/material';
// components
import HeaderBreadcrumbs from '../../components/header-breadcrumbs';
import { Widget404, LoadSkeleton } from '../../components/widegts';
import Markdown from '../../components/markdown';

// APP Name
import { AppName } from '../../config';

// redux
import { useDispatch, useSelector } from '../../redux/store';

// data
import { getCms } from '../../redux/slices/cms';

// ----------------------------------------------------------------------

export default function RefundPage() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { cms, error, isLoading } = useSelector((state) => state.cms);
  const pageTitle = 'Refund and Policy';

  useEffect(() => {
    dispatch(getCms('rf'));
  }, [dispatch]);

  if (!cms && isLoading) {
    return <LoadSkeleton />;
  }
  if (!cms && error) {
    return <Widget404 item={pageTitle} subitem={`No data found as ${pageTitle} `} />;
  }
  if (!cms) {
    return <LoadSkeleton />;
  }

  return (
    <>
      <Helmet>
        <title>
          {pageTitle} | {AppName}
        </title>
      </Helmet>

      <Container maxWidth="xl">
        <HeaderBreadcrumbs
          heading={pageTitle}
          links={[
            {
              name: 'Home',
              href: '/',
            },
            { name: `${pageTitle}` },
          ]}
        />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ p: { xs: 3, md: 5 } }}>
              <Typography variant="h6" sx={{ mb: 5 }}>
                {pageTitle}
              </Typography>

              <Markdown children={cms.content} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
